import React from "react";
import moment from "moment";
import Spinner from "./Spinner";

const trimDecimals = (value: number) => {
    return Math.round(value * 100) / 100
}
const computePercent = (start: number, end: number, current: number) => {
    return trimDecimals(((current - start) / (end - start)) * 100)
}

const createGraphData = (device: any, range: number) => {
    if (device == null) {
        return "linear-gradient(to right, transparent 50%)"
    }

    let start = new Date().getTime() - range * 60 * 60 * 1000
    let end = new Date().getTime()

    let metrics: any[] = device.metrics.connectivity.filter((value: any, index: number) => (
        value.time >= start
    ))

    if (device.metrics == null || metrics.length == 0) {
        if (device.status === "offline") {
            return "linear-gradient(to right, red 0 100%)"
        } else {
            return "linear-gradient(to right, green 0 100%)"
        }
    }

    let initialColor = metrics[0].is_online ? "red" : "green"
    let firstEntryStart = computePercent(start, end, metrics[0].time)
    let result = `linear-gradient(to right, ${initialColor} 0% ${firstEntryStart}%,`

    metrics.forEach((value, index) => {
        let startPercent = computePercent(start, end, value.time)
        let endPercent = 0
        if (index < metrics.length - 1) {
            endPercent = computePercent(start, end, metrics[index + 1].time)
        }

        let online = value.is_online
        if (!online && endPercent - startPercent < 0.25) {
            let overflow = (0.25 - (endPercent - startPercent)) / 2
            startPercent = trimDecimals(startPercent - overflow)
            endPercent = trimDecimals(endPercent + overflow)
        }

        let percent = `${startPercent}%`
        if (endPercent != 0) {
            percent += ` ${endPercent}%`
        }

        if (online) {
            result += `green ${percent},`
        } else {
            result += `red ${percent},`
        }
    })

    result = result.slice(0, -1)
    result += ")"

    return result
}

const timeline = (rangeHours: number) => {
    let now = moment(Date.now()).subtract({hours: rangeHours})
    let times: any[] = []
    let stepMinutes = rangeHours * 60 / 6
    Array(7).fill(0).forEach(() => {
        times.push(now.format("HH:mm"))
        now.add({minute: stepMinutes})
    })
    times[times.length - 1] = "Now"
    return times
}

const ConnectivityGraph: React.FC<{ device: any, range: number }> = ({device, range}) => {
    const contents = () => {
        if (device == null) {
            return <div className="w-full h-10 flex justify-center"><Spinner size={30} color="gray"></Spinner></div>
        }
        return (
            <div className="w-full h-10" style={{background: `${createGraphData(device, range)}`}}></div>
        )

    }
    return (<div className="container">
        {contents()}
        <ul className="flex items-stretch justify-between w-full mt-2 p-0">
            {timeline(range).map(time => (
                <li key={time} className="block list-none text-xs">{time}</li>
            ))}
        </ul>
    </div>)
}

export default ConnectivityGraph;
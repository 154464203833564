import useClickOutside from "./utils/useClickOutside";

const Modal: React.FC<{
  children: JSX.Element | Array<JSX.Element> | string;
  onClickOutside: any;
  visible: boolean;
  maxWidthClass?: string;
  backgroundColorClass?: string;
}> = ({ children, onClickOutside, visible, maxWidthClass, backgroundColorClass }) => {
  const modalRef = useClickOutside(() => {
    if (!visible) {
      return;
    }
    onClickOutside && onClickOutside();
  });

  if (!visible) {
    return <></>;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          ref={modalRef}
          className={`inline-block align-bottom ${
            !!backgroundColorClass ? backgroundColorClass : "bg-white"
          } rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
            !!maxWidthClass ? maxWidthClass : "max-w-xl"
          } w-full`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;

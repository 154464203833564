import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const BuildStripeOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
  isReseller: boolean;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
  isReseller
}) => {
  const porcentageToNumeric = (number: any) => number && (Number.parseFloat(number) / 100).toFixed(3);

  const currencyToCents = (number: any) => number && (Number.parseFloat(number) * 100).toFixed(2);
  
  const isConnected = !(location.settings?.stripe_payments?.connected_account_id === null ||
    (isReseller && location.settings?.stripe_payments?.api_secret_key === null ));

  const SetLocation = (data: any) => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;

    let postBody = {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          stripe_payments: data,
        },
      },
    } as any;
    return API.post("Conduit", endpoint, postBody);
  };

  const disconnectFromStripe = () => {
    SetLocation({ connected_account_id: "", api_secret_key: "" })
      .then(() => {
        updateLocation();
        reset({ connected_account_id: "", api_secret_key: "" });
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const connectToStripe = (formData: any) => {
    const { connected_account_id, api_secret_key } = formData;
    SetLocation({ connected_account_id, api_secret_key })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveSettings = (formData: any) => {
    const { fee_percent_rate, fee_fixed_rate } = formData;
    SetLocation({ fee_percent_rate: porcentageToNumeric(fee_percent_rate), fee_fixed_rate: currencyToCents(fee_fixed_rate) })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromStripe}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Connect</span>
      </>
    </button>
  );
  const saveButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Save</span>
      </>
    </button>
  );

  return (
    <div>
      <form className="h-full" onSubmit={handleSubmit(connectToStripe)}>
        <div className="flex flex-col justify-between h-full">
          <div className="space-y-3">
            <div className="text-base text-center w-full">
              Collect your information<br/>from{" "}
              <a className="text-copper-purple" href="https://dashboard.stripe.com/">Stripe.</a>
            </div>
            <div>
              <label htmlFor="connected_account_id" className="block text-base py-1 text-gray-700">Merchant Connected Account ID</label>
              <input
                ref={register()}
                id="connected-account-id"
                name="connected_account_id"
                disabled={isConnected}
                autoComplete="off"
                className={isConnected ? "copper-input-disabled" : "copper-input"}
                defaultValue={location.settings?.stripe_payments?.connected_account_id}
              />
            </div>
            {isReseller && (
              <div>
                <label htmlFor="api_secret_key" className="block text-base py-1 text-gray-700">Reseller Platform API Key</label>
                <input
                  ref={register()}
                  id="api-secret-key"
                  name="api_secret_key"
                  disabled={isConnected}
                  autoComplete="off"
                  className={isConnected ? "copper-input-disabled" : "copper-input"}
                  defaultValue={location.settings?.stripe_payments?.api_secret_key}
                />
              </div>
            )}
            <div className="flex justify-between">
              {!!requestError ? <span className="label-error my-auto">{requestError}</span> : <div />}
              {isConnected ? disconnectButton : connectButton}
            </div>
          </div>
        </div>
      </form>
      {isReseller && (
        <form className="h-full" onSubmit={handleSubmit(saveSettings)}>
          <div className="flex flex-col justify-between h-full">
            <div className="space-y-3">
              <div className="flex h-10 items-center pt-4">
                <span className="block text-base py-1 text-gray-700 mr-1">
                  Pay-at-the-table fee
                </span>
                <div className="flex items-center align-middle p-2px">
                  <input
                    id="fee-percent-rate"
                    name="fee_percent_rate"
                    ref={register()}
                    className="copper-input w-16 mr-1"
                    defaultValue={(location.settings?.stripe_payments?.fee_percent_rate * 100).toFixed(2)}
                  />
                  %
                </div>
                <span className="text-sm fsont-medium p-16px text-gray-900">
                  +
                </span>
                <div className="flex items-center align-middle p-2px">
                  <span className="text-sm fsont-medium text-gray-900 mr-1">
                    $
                  </span>
                  <input
                    id="fee-fixed-rate"
                    name="fee_fixed_rate"
                    ref={register()}
                    className="copper-input w-16"
                    defaultValue={(location.settings?.stripe_payments?.fee_fixed_rate / 100).toFixed(2)}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                {saveButton}
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const StripeTab = {
  name: "Stripe",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className={!active ? "image-grayscale" : ""}
        width="112"
        alt="stripe-logo"
        src={`/img/payment_options/stripe-active.svg`}
      />
    </>
  ),
  headerText: "Connect with Stripe",
  build: BuildStripeOptions,
};

export default React.memo(BuildStripeOptions);

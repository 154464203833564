import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import AuthBackdrop from "../../components/auth/AuthBackdrop";
import { useAuth } from "../../services/auth-context";

const ResetScreen: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isLoggedIn } = useAuth();
  const [error, setError] = useState("");

  const requestForgotPassword = async (formData: any) => {
    const { email } = formData;

    try {
      setError("");
      Auth.forgotPassword(email)
        .then((res) => {
          setIsSubmitted(res);
        })
        .catch((err) => {
          setError(err.message);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoggedIn === true) return <Redirect to={{ pathname: "/" }} />;

  return (
    <AuthBackdrop>
      <>
        {isSubmitted
          ? (
            thankYou()
          )
          : (
            <>
              <h2 className="auth-title">Reset your password</h2>
              <span className="text-responsive-base">
                Enter the email address associated with your account and we'll send you a link to reset your password.
              </span>
              <form className="space-y-6" onSubmit={handleSubmit(requestForgotPassword)}>
                <div className="pt-8">
                  <label htmlFor="email" className="auth-label">
                    Sign in to your account
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="copper-input"
                    />
                  </div>
                </div>

                {!!error && <p className="label-error">{error}</p>}

                <div>
                  <button type="submit" className="copper-button-confirm-full">
                    Continue
                  </button>

                  <a href="/login" className="auth-link">
                    Return to sign in
                  </a>
                </div>
              </form>
            </>
          )}
      </>
    </AuthBackdrop>
  );
};

const thankYou = () => (
  <div className="py-3 space-y-2">
    <div className="text-responsive-2xl font-normal">
      <p className="py-2">Thanks!</p>
      <p className="leading-6">Check your email for instructions to reset your password</p>
    </div>
    <div className="text-responsive-base py-6">
      <span>Didn't get the email? Check your spam folder or</span>
      <span>
        {" "}
        <a href="/reset" className="text-color-link">
          Resend
        </a>
        .
      </span>
    </div>
    <a className="auth-link p-2" href="/">
      Return to sign in
    </a>
  </div>
);

export default ResetScreen;

import { addressToString } from "../../util/addressToString";
import DataTable from "../DataTable";
import PlaceHolderDash from "../PlaceholderDash";
import Spinner from "../Spinner";

const MerchantsList: React.FC<{ merchants: any; history: any }> = ({ merchants, history }) => {
  if (!merchants) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }

  const mappedMerchants = merchants?.map(({ id, name, address }: any) => ({
    name,
    locations: <PlaceHolderDash />,
    address: addressToString(address),
    click: () => {
      history.push(`/merchants/${id}`);
    },
  })) || [];

  const columns = [
    {
      name: "name",
      label: "Name",
      type: "string",
    },

    {
      name: "locations",
      label: "",
      type: "string",
    },

    {
      name: "address",
      label: "Address",
      type: "string",
    },
  ];

  return (
    <DataTable
      data={mappedMerchants}
      noItemsHeader="No merchants created"
      noItemsBody="Merchants must be created before being visible here."
      headerClasses="pl-responsive-sm"
      tdClass="px-responsive-sm py-responsive-sm"
      noBorderTopOnHeaders={true}
      columns={columns}
      disableFullWidthCells={true}
      disableFlexBetween={true}
      enableHalfWidthCells={true}
      showBottomBorder={true}
    />
  );
};

export default MerchantsList;

import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import DataContainer from "../components/DataContainer";
import LocationsList from "../components/lists/LocationsList";
import LocationModal from "../components/modals/LocationModal";
import { useAuth } from "../services/auth-context";
import Main from "./containers/Main";

const Locations: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [locations, setLocations] = useState<any[] | any>(null);
  const [showingNewModal, setShowingNewModal] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    })
      .then((locations) => {
        if (locations.data) {
          setLocations(locations.data);
        }
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      });
  }, []);

  const onModalSubmitSuccess = () => {
    setShowingNewModal(false);
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 20 },
    }).then((locations) => {
      if (locations.data) {
        setLocations(locations.data);
      }
    });
  };

  const onModalCancel = () => {
    setShowingNewModal(false);
  };

  const errorDisplay = <CenteredErrorLabel message={error} />;

  return (
    <Main>
      <DataContainer
        title="Locations"
        removeInnerPadding={true}
        controls={
          <Button onClick={() => setShowingNewModal(true)} icon="ion-android-add">
            New
          </Button>
        }
      >
        {!!error ? errorDisplay : <LocationsList locations={locations} history={history} />}
      </DataContainer>
      <LocationModal
        visible={showingNewModal}
        onCancel={onModalCancel}
        onSubmitSuccess={onModalSubmitSuccess}
        onClickOutside={onModalCancel}
        removeBlankFormValues={true}
        merchantId={user?.account}
        devices={[]}
        preselectedCountry={user?.accountData?.address?.country}
      />
    </Main>
  );
};

export default Locations;

export function renderAmountRounded(amount: number, currency: string) {
    return (amount / 100).toLocaleString(void 0, { style: "currency", currency: currency, maximumFractionDigits: 0 });
}

export function renderAmount(amount: number, currency: string) {
    return (amount / 100).toLocaleString(void 0, { style: "currency", currency: currency });
}

export function renderAmountThousands(amount: number) {
    return `$${amount < 100000 ? Math.round(amount / 100) : Math.round(amount / 100000) + "k"}`;
}
import React from "react";
import FormInputError from "./FormInputError";

const FormInput: React.FC<{
  formRegisterRef: any;
  id: string;
  placeholder?: string;
  defaultValue: string;
  error?: { message: string };
  onChange?: Function;
  onBlur?: Function;
}> = (props) => {
  const { formRegisterRef, onChange, onBlur, error, id, ...rest } = props;
  return (
    <div>
      <input
        ref={formRegisterRef}
        className="copper-input"
        {...rest}
        name={id}
        onChange={(e) => onChange && onChange(e)}
        onBlur={(e) => onBlur && onBlur(e)}
      />
      <FormInputError error={error?.message} />
    </div>
  );
};

export default FormInput;

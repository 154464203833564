import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "aws-amplify";
import UploadImage from "../../components/UploadImage";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import FormInput from "../FormInput";
import LeftLabeledContainer from "../LeftLabeledContainer";
import {appendHttpsIfMissing} from "../../util/urls";

Storage.configure({
  customPrefix: {
    public: "",
    protected: "protected/",
    private: "private/",
  },
});

const uploadImage = async (key: string, file: any): Promise<object> =>
  Storage.put(
    key,
    file,
    {
      level: "public",
      contentType: "image/png",
    },
  );
const removeImage = async (key: string): Promise<object> =>
  Storage.remove(
    key,
    {
      level: "public",
    },
  );
const urlCheck = async (url: any) => {
  return (await fetch(url)).status === 200;
};

const BrandingLocationModal: React.FC<{
  location?: any;
  merchantId: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  updateLocation: Function;
}> = ({ location, merchantId, onCancel, onSubmitSuccess, onClickOutside, visible, updateLocation }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [iconImage, setIconImage] = useState<any>("");
  const [logoImage, setLogoImage] = useState<any>("");
  const [removeImages, setRemoveImages] = useState<any>({});

  const ICON_KEY = `${location.id}/icon/64x64.png`;
  const LOGO_KEY = `${location.id}/logo/original.png`;

  const getBranding = async () => {
    const icon = await Storage.get(ICON_KEY);
    const iconTest = await urlCheck(icon);
    iconTest && setIconImage(icon);

    const logo = await Storage.get(LOGO_KEY);
    const logoTest = await urlCheck(logo);
    logoTest && setLogoImage(logo);
  };

  useEffect(() => {
    getBranding();
  }, []);

  const onFormSubmit = async (formData: any) => {
    if (!formData) return;
    const { logo, icon } = formData;
    try {
      console.log("formData", formData);
      const iconFile = icon[0];
      const logoFile = logo[0];
      if (iconFile) {
        await uploadImage(ICON_KEY, iconFile);
      } else {
        if (iconImage.length > 0 && removeImages.icon) {
          await removeImage(ICON_KEY);
          setIconImage("");
          console.log("Remove Image icon");
        }
      }
      if (logoFile) {
        await uploadImage(LOGO_KEY, logoFile);
      } else {
        if (logoImage.length > 0 && removeImages.logo) {
          await removeImage(LOGO_KEY);
          setLogoImage("");
          console.log("Remove Image logo");
        }
      }
      getBranding();
      console.log("removeImages", removeImages);
      console.log("iconImage", iconImage);
      console.log("logoImage", logoImage);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }

    setIsSubmitting(true);

    const endpoint = `/locations/${location.id}`;
    console.log(location)
    await API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: {
        website: formData.website,
        settings: {
          qr_payments: {
            intro: formData.branding_intro,
            outro: formData.branding_outro,
          }
        }
      }
    })
    .then(() => {
      updateLocation();
    })
    .catch((err) => {
      setRequestError(err.response.data.error.message);
    });

    //onSubmitSuccess();
    setIsSubmitting(false);
    getBranding();
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title=""
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
        backgroundColorClass="bg-white"
      >
        <h2 className="text-2xl text-center">Branding</h2>
        <div className="space-y-3">
          <UploadImage
            name="icon"
            imageUrl={iconImage}
            register={register}
            label="Upload Icon"
            onRemoveImage={() => setRemoveImages({ ...removeImages, icon: ICON_KEY })}
            toolTip="Icon is display on the QR code payment flow"
            description="Image size required: 64x64"
            imageClass="h-auto w-20"
            fixedSize="64x64"
            uploadButtonClass="w-5/6"
            minWidth={20}
          />
          <UploadImage
            name="logo"
            imageUrl={logoImage}
            register={register}
            label="Upload Logo"
            onRemoveImage={() => setRemoveImages({ ...removeImages, logo: LOGO_KEY })}
            toolTip="Logo is displayed on digital customer receipt"
            description="Image size required: 500x500"
            fixedSize="500x500"
            imageClass="h-auto w-36"
            uploadButtonClass="w-5/6"
            minWidth={36}
          />
        </div>

        <LeftLabeledContainer name="Website URL">
          <FormInput
              id="website"
              defaultValue={location?.website ?? ""}
              placeholder={"URL"}
              error={errors?.website}
              onChange={(e: any) => {
                let url: string = e.target.value;
                if (url.length < 5 || url.startsWith("http")) {
                  return;
                }
                url = appendHttpsIfMissing(url)
                e.target.value = url
              }}
              formRegisterRef={register({
                pattern: {
                  value: /^$|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                  message: "Incorrect URL format"
                }
              })}
          />
        </LeftLabeledContainer>

        <LeftLabeledContainer name="Before QR">
          <textarea
            cols={50}
            id="branding-intro"
            name="branding_intro"
            className={`copper-input text-responsive-xs max-h-96`}
            defaultValue={location?.settings?.qr_payments?.intro ?? ""}
            placeholder={"Pay this check with your phone\r\nPowered by Copper"}
            ref={register()}
          />
        </LeftLabeledContainer>

        <LeftLabeledContainer name="After QR">

          <textarea
            cols={50}
            rows={3}
            id="branding-outro"
            name="branding_outro"
            className={`copper-input text-responsive-xs max-h-96`}
            defaultValue={location?.settings?.qr_payments?.outro ?? ""}
            placeholder={"No app or signup required\r\nSimply scan the QR code with your camera\r\nor open your browser and go to cop.pe"}
            ref={register()}
          />
        </LeftLabeledContainer>

      </ModalForm>
    </Modal>
  );
};

export default BrandingLocationModal;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import MerchantsList from "../components/lists/MerchantsList";
import MainHeader from "../components/MainHeader";
import MerchantModal from "../components/modals/MerchantModal";
import PaginationControls from "../components/PaginationControls";
import Main from "./containers/Main";
import {useAuth} from "../services/auth-context";

const Merchants: React.FC = () => {
  const history = useHistory();
  const [merchants, setMerchants] = useState<any[] | any>(null);
  const [showingNewModal, setShowingNewModal] = useState(false);
  const {user}= useAuth()

  const onModalSubmitSuccess = (newMerchant: any) => {
    setShowingNewModal(false);
    history.push(`/merchants/${newMerchant.id}`);
  };

  const onModalCancel = () => {
    setShowingNewModal(false);
  };

  return (
    <Main>
      <div className="p-4 flex justify-between pb-8">
        <MainHeader>Merchants</MainHeader>
        <Button onClick={() => setShowingNewModal(true)} icon="ion-android-add">
          New
        </Button>
      </div>
      <MerchantsList merchants={merchants} history={history} />
      <PaginationControls
        endpoint="/accounts"
        onListChange={setMerchants}
        requestParameters={{ type: "merchant" }}
      />
      <MerchantModal
        visible={showingNewModal}
        onCancel={onModalCancel}
        onSubmitSuccess={onModalSubmitSuccess}
        onClickOutside={onModalCancel}
        removeBlankFormValues={true}
        sendType={true}
        parentAccount={user}
      />
    </Main>
  );
};

export default Merchants;

import {API} from "aws-amplify";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Button from "../Button";
import DeviceIcon from "../DeviceIcon";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import Spinner from "../Spinner";
import ConfirmModal from "./ConfirmModal";
import WiFiStrengthDescription from "../WiFiStrengthDescription";
import Toggle from "../Toggle";
import ConnectivityGraph from "../ConnectivityGraph";
import {RadioGroup} from "@headlessui/react";


const timeRanges = [
    {name: '24h', value: 24},
    {name: '12h', value: 12},
    {name: '6h', value: 6},
    {name: '3h', value: 3},
    {name: '1h', value: 1},
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const DeviceModal: React.FC<{
    device: any;
    onCancel: any;
    onSubmitSuccess: any;
    onClickOutside?: any;
    visible: boolean;
    onRemove: any;
    onEdited: any,
    merchantId?: string;
}> = ({device, onCancel, onSubmitSuccess, onRemove, onEdited, onClickOutside, visible, merchantId}) => {
    const {register, handleSubmit} = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [inEditMode, setInEditMode] = useState(false);
    const [isShowingRemoveConfirm, setIsShowingRemoveConfirm] = useState(false);
    const [isShowingRebootConfirm, setIsShowingRebootConfirm] = useState(false);
    const [isSubmittingConfirmation, setIsSubmittingConfirmation] = useState(false);

    const [selectedTimeRange, setSelectedTimeRange] = useState(timeRanges[0])
    const [deviceEnabled, setDeviceEnabled] = useState(false);

    const [deviceEx, setDeviceEx] = useState<any>(null);

    const shouldReloadDevice = () => {
        if (!visible || device == null) {
            return false
        }
        if (deviceEx == null) {
            return true
        }
        return device.id != deviceEx.id
    }
    const reloadDevice = () => {

        API.get("Conduit", `/devices/${device.id}?include[0]=metrics&include[1]=last_reboot`, {
            headers: {"copper-account": merchantId},
        }).then((reloaded) => {
            setDeviceEx(reloaded)
        });
    }

    useEffect(() => {
        if (shouldReloadDevice()) {
            reloadDevice();
        }
    })

    if (deviceEnabled != device?.enabled) {
        setDeviceEnabled(device?.enabled)
    }

    const deviceLabel = device?.label || device?.id.split("_")[1];
    const avoidCancelWhenRemoveConfirming = () => {
        if (isShowingRemoveConfirm || isShowingRebootConfirm) {
            return;
        }
        onClickOutside();
    };

    const onFormSubmit = ({label}: any) => {
        if (device.label === label) {
            setInEditMode(false);
            return;
        }
        setIsSubmitting(true);
        const deviceId = device.id;
        API.post("Conduit", `/devices/${deviceId}`, {
            headers: merchantId ? {"copper-account": merchantId} : {},
            body: {
                label: label.trim(),
            },
        })
            .then((updatedDevice) => {
                console.log("/devices/", "then");
                device.label = updatedDevice.label;
                onSubmitSuccess();
            })
            .catch((err) => {
                console.log("/devices/", "res", err);
                setRequestError(err.message);
            })
            .finally(() => {
                console.log("/devices/", "finally");
                setIsSubmitting(false);
                setInEditMode(false);
            });
    };

    const onDeviceEnabled = (enabled: boolean) => {
        const deviceId = device.id;
        API.post("Conduit", `/devices/${deviceId}`, {
            headers: merchantId ? {"copper-account": merchantId} : {},
            body: {
                enabled: enabled,
            },
        })
            .then(updatedDevice => {
                device.enabled = updatedDevice.enabled
                setDeviceEnabled(updatedDevice.enabled);
                onEdited()
            })
            .catch((err) => {
                console.log("/devices/", "res", err);
                setRequestError(err.message);
            })
    };

    const onModalCancel = () => {
        onCancel();
        setRequestError("");
        setIsSubmitting(false);
    };

    const onRemoveClicked = () => {
        setIsShowingRemoveConfirm(true);
    };

    const onCancelClicked = () => {
        setInEditMode(false);
        onModalCancel();
    };

    const onConfirmRemove = (e: any) => {
        e.preventDefault();
        setIsSubmittingConfirmation(true);
        API.del("Conduit", `/devices/${device.id}`, {
            headers: merchantId ? {"copper-account": merchantId} : {},
        })
            .then((res) => {
                if (onRemove) {
                    onRemove();
                    onCancel();
                }
            })
            .catch((err) => {
                setRequestError(err.response.data.error.message);
            })
            .finally(() => {
                setIsShowingRemoveConfirm(false);
                setIsSubmittingConfirmation(false);
                onCancelClicked();
            });
    };
    const onRemoveCancelClicked = () => {
        setIsShowingRemoveConfirm(false);
    };

    const onConfirmReboot = (e: any) => {
        e.preventDefault();
        API.post("Conduit", `/devices/${device.id}/restart`, {
            headers: merchantId ? {"copper-account": merchantId} : {},
        })
            .then((res) => {
            })
            .catch((err) => {
                setRequestError(err.response.data.error.message);
            })
            .finally(() => {
                setIsShowingRebootConfirm(false);
                onCancelClicked();
            });
    };

    return (
        <>
            <Modal
                onClickOutside={avoidCancelWhenRemoveConfirming}
                visible={visible}
                maxWidthClass="max-w-2xl"
                backgroundColorClass="bg-form"
            >
                <ModalForm
                    title={
                        <div className="flex">
                            <div className="flex text-responsive-2xl font-medium pr-2">
                                <div className="w-min-32px w-8 my-auto">{getDeviceIcon(device)}</div>
                                {inEditMode
                                    ? (
                                        <input
                                            ref={register()}
                                            id="label"
                                            name="label"
                                            className="copper-input -mb-2px h-9 -mt-0.5"
                                            placeholder="Device Label"
                                            defaultValue={deviceLabel}
                                        />
                                    )
                                    : <div>{deviceLabel}</div>}
                            </div>
                        </div>
                    }
                    isSubmitting={isSubmitting}
                    onCancel={onCancelClicked}
                    cancelButtonText="Close"
                    footerBtns={[{
                        label: "Reboot",
                        onClick: () => setIsShowingRebootConfirm(true),
                    }]}
                    handleSubmit={handleSubmit(onFormSubmit)}
                    submitError={requestError}
                    hideSaveButton={true}
                    controls={!inEditMode
                        ? (
                            <div className="space-x-2 -mb-1">
                                <Button onClick={() => setInEditMode(true)} icon="ion-edit" iconSize={18}>
                                    Edit
                                </Button>
                                <Button onClick={onRemoveClicked} icon="ion-trash-b" iconSize={20}>
                                    Remove
                                </Button>
                            </div>
                        )
                        : (
                            <div className="flex space-x-2 -mb-1">
                                <Button onClick={() => setInEditMode(false)}>Cancel</Button>

                                <button
                                    type="submit"
                                    className={`copper-button-confirm ${
                                        isSubmitting && "text-transparent"
                                    } ml-2 flex justify-center bg-color-red h-10`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <div
                                            className="absolute flex align-center justify-center mx-auto -my-0.5 px-1 md:my-0 md:px-1.5">
                                            <Spinner size={20} color="white"></Spinner>
                                        </div>
                                    )}
                                    Save
                                </button>
                            </div>
                        )}
                    backgroundColorClass="bg-white"
                >
                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Enabled</div>
                        </div>
                        <span className="copper-label w-full">
                  <Toggle
                      onChange={() => {
                          const newValue = !deviceEnabled;
                          setDeviceEnabled(newValue)
                          onDeviceEnabled(newValue)
                      }}
                      value={deviceEnabled}
                  />
            </span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Device ID</div>
                        </div>
                        <span className="copper-label w-full">{device?.id}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Serial number</div>
                        </div>
                        <span className="copper-label w-full">{device?.serial_number}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Model</div>
                        </div>
                        <span className="copper-label w-full">{device?.device_type}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Firmware version</div>
                        </div>
                        <span className="copper-label w-full">{getFirmwareVersion(device)}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Last seen</div>
                        </div>
                        <span className="copper-label w-full">{moment(device?.last_seen).fromNow()}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Last reported Wi-Fi strength</div>
                        </div>
                        <span className="copper-label w-full">{WiFiStrengthDescription(device)}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Last reboot</div>
                        </div>
                        <span className="copper-label w-full">{deviceEx?.last_reboot ? moment(deviceEx?.last_reboot).fromNow() : "Unknown"}</span>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className={`text-gray-500 font-medium my-auto ${device?.restart_required ? "text-red-700 font-bold" : ""}`}>Reboot required</div>
                        </div>
                        <span className={`copper-label w-full ${device?.restart_required ? "text-red-700 font-bold" : ""}`}>{device?.restart_required ? "Yes":"No"}</span>
                    </div>


                    <div className="flex justify-evenly">
                        <div className="w-full my-auto">
                            <div className="text-gray-500 font-medium my-auto">Connectivity</div>
                        </div>
                        <span className="copper-label w-full">
                            <div className="inline-flex " role="group">
                                <RadioGroup value={selectedTimeRange} onChange={setSelectedTimeRange} className="mt-2">
                                        <div className="grid grid-cols-3 gap-3 sm:grid-cols-5">
                                          {timeRanges.map((timeRange) => (
                                              <RadioGroup.Option
                                                  key={timeRange.name}
                                                  value={timeRange}
                                                  className={({ active, checked }) =>
                                                      classNames(
                                                          checked
                                                              ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                                                              : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                                          'border rounded-lg py-2 px-2 flex items-center justify-center text-sm font-medium sm:flex-1'
                                                      )
                                                  }
                                              >
                                                  <RadioGroup.Label as="span">{timeRange.name}</RadioGroup.Label>
                                              </RadioGroup.Option>
                                          ))}
                                        </div>
                                      </RadioGroup>
                                    </div>
                        </span>
                    </div>

                    <div className="flex justify-evenly">
                        <ConnectivityGraph device={shouldReloadDevice() ? null : deviceEx}
                                           range={selectedTimeRange.value}></ConnectivityGraph>
                    </div>

                </ModalForm>
            </Modal>
            <ConfirmModal
                onClickOutside={onRemoveCancelClicked}
                visible={isShowingRemoveConfirm}
                title={"Remove device"}
                onConfirmClicked={onConfirmRemove}
                onCancelClicked={onRemoveCancelClicked}
                bodyText={`This will remove device ${deviceLabel} from this location. Are you sure?`}
                buttonText="Remove device"
                isSubmitting={isSubmittingConfirmation}
            />
            <ConfirmModal
                onClickOutside={() => setIsShowingRebootConfirm(false)}
                visible={isShowingRebootConfirm}
                title={"Reboot device"}
                onConfirmClicked={onConfirmReboot}
                onCancelClicked={() => setIsShowingRebootConfirm(false)}
                bodyText={`This will reboot device ${deviceLabel}. Are you sure?`}
                buttonText="Reboot device"
                isSubmitting={isSubmittingConfirmation}
            />
        </>
    );
};

const getDeviceIcon: React.FC<any> = (device) => <DeviceIcon device={device}/>;
const getFirmwareVersion = (device: any) => {
    if (!device) {
        return "";
    }

    return device.device_sw_version?.split("+")[0] ?? "";
};

export default DeviceModal;

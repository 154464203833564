import React from "react";

const Badge: React.FC<any> = ({ color, children, css }) => {
  const baseClass = "capitalize px-2 inline-flex text-xs leading-5 font-semibold rounded-md";
  const _color = `bg-${color}-100 text-${color}-800`;
  return <span className={`${baseClass} ${_color} ${css}`}>{children}</span>;
};

export const LogicBadge: React.FC<any> = ({ definition, value, full, css }) => {
  const baseClass = `capitalize justify-center ${
    full ? "w-full" : ""
  } px-3 py-0.5 inline-flex text-12px leading-5 font-normal rounded-md`;
  const color = definition[value]?.color || "black";
  const backgroundColor = definition[value]?.background || definition[value];

  const style = {
    backgroundColor: backgroundColor,
    color: color,
  };

  let _color = `bg-${backgroundColor}-100 text-${color}-800`;
  return (
    <span className={`${baseClass} ${_color} ${css} font-medium`} style={definition[value]?.color && style}>
      {value}
    </span>
  );
};

export default Badge;

import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const BuildBluefinOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
}) => {
  const isConnected = !(location.settings?.bluefin_payments?.account_id === null
    || location.settings?.bluefin_payments?.api_access_key === null);

  const SetLocation = (data: any) => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;

    let postBody = {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          bluefin_payments: data,
        },
      },
    } as any;
    return API.post("Conduit", endpoint, postBody);
  };

  const disconnectFromBluefin = () => {
    SetLocation("")
      .then(() => {
        updateLocation();
        reset({ account_id: "", api_access_key: "" });
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveSettings = (formData: any) => {
    const { account_id, api_access_key } = formData;
    SetLocation({ account_id, api_access_key })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromBluefin}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Connect</span>
      </>
    </button>
  );

  return (
    <form className="h-full" onSubmit={handleSubmit(saveSettings)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-3">
          <div className="text-base text-center w-full">
            Collect your Account ID and API Key<br />from{" "}
            <a className="text-copper-purple" href="https://bluefin.com/">Bluefin.</a>
          </div>
          <div>
            <label htmlFor="account-id" className="block text-base py-1 text-gray-700">Account ID</label>
            <input
              ref={register({ required: "Account ID required" })}
              id="account-id"
              name="account_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.bluefin_payments?.account_id}
            />
          </div>
          <div>
            <label htmlFor="api_access_key" className="block text-base py-1 text-gray-700">
              API Key
            </label>
            <input
              ref={register({ required: "API Key required" })}
              id="api-access-key"
              name="api_access_key"
              disabled={isConnected}
              autoComplete="off"
              type={isConnected ? "password" : "text"}
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={isConnected ? "**************" : location.settings?.bluefin_payments?.api_access_key}
            />
          </div>
        </div>
        <div className="flex justify-between">
          {!!requestError ? <span className="label-error my-auto">{requestError}</span> : <div />}
          {isConnected ? disconnectButton : connectButton}
        </div>
      </div>
    </form>
  );
};

export const BluefinTab = {
  name: "Bluefin",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className=""
        width="112"
        alt="bluefin-logo"
        src={`/img/payment_options/bluefin-${active ? "active" : "inactive"}.svg`}
      />
    </>
  ),
  headerText: "Connect with Bluefin",
  build: BuildBluefinOptions,
};

export default React.memo(BuildBluefinOptions);

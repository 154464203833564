import { FunctionComponent } from "react";
import MainHeader from "../../components/MainHeader/";
import Sidebar from "../../components/Sidebar";

const Main: FunctionComponent = ({ children }) => {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-background">
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <MainHeader />
        <div className="p-6 font-sans	 border-gray-light h-screen bg-white overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Main;

import React from "react";

const InTheLastSettings: React.FC<any> = (props) => {
  const { filterSettings, setFilterSettings } = props;
  return (
    <>
      <input
        className="border copper-input-basic rounded text-gray-500 text-center mr-2 w-8"
        defaultValue="6"
        value={filterSettings.dateRange.amount}
        onChange={(e) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              amount: e.target.value,
            },
          })}
      />
      <select
        className="bg-white copper-input-basic rounded text-gray-500 p-0.5 px-1.5"
        value={filterSettings.dateRange.period}
        onChange={(e) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              period: e.target.value,
            },
          })}
      >
        <option value="hours">hours</option>
        <option value="days">days</option>
        <option value="weeks">weeks</option>
      </select>
    </>
  );
};

export default InTheLastSettings;

import React, { FunctionComponent, useState } from "react";
import useClickOutside from "../utils/useClickOutside";

interface UserDropdownType {
  name: string;
  email: string;
}
const UserButton: FunctionComponent<UserDropdownType> = (props) => {
  const [isOpenDropdown, setOpenDrowDown] = useState(false);

  const DropdownRef = useClickOutside(() => setOpenDrowDown(false));

  return (
    <div ref={DropdownRef} className="ml-auto flex flex-row justify-end">
      <button
        className="p-4 mr-3 max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        id="user-menu"
        aria-haspopup="true"
        onClick={() => setOpenDrowDown(!isOpenDropdown)}
      >
        <div className="bg-gray-500 p-4 w-0 h-0 rounded-full text-white text-sm">
          <div className="-m-2">{props.name.split(" ").map((name) => name[0])}</div>
        </div>
      </button>
      {isOpenDropdown && (
        <div
          className="origin-top-right absolute right-1.5 top-7 m-6 w-57 rounded-md border border-gray-200 shadow-lg bg-white  text-responsive-base"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div className="bg-gray-100 px-5 py-4 leading-0">
            <div className="text-gray-400">Signed in as</div>
            <div className="overflow-x-hidden overflow-ellipsis">{props.email}</div>
          </div>
          <a href="/profile" className="block px-5 pt-2 pb-3 text-sm text-copper-purple" role="menuitem">
            View Profile
          </a>
          <a href="/logout" className="block px-5 pb-2 text-sm  text-copper-purple" role="menuitem">
            Sign Out
          </a>
        </div>
      )}
    </div>
  );
};

export default UserButton;

import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const { REACT_APP_API_ENDPOINT } = process.env;

const BuildPoyntOptions: React.FC<{
  location: any;
  updateLocation?: any;
  merchantId: string;
  register?: any;
  handleSubmit?: any;
  setValue?: any;
  isSubmitting?: any;
  setIsSubmitting?: any;
  requestError?: any;
  setRequestError?: any;
  isReseller: boolean;
}> = ({ location, updateLocation, merchantId, isSubmitting, setIsSubmitting, setRequestError }) => {
  const isConnectedToPoynt = (location.settings?.poynt_payments?.business_id !== null);
  const popupUrl = `${REACT_APP_API_ENDPOINT}/poynt/authorize?location=${location.id}`;
  const openPoyntAuthPopup = () => {
    const popup = window.open(popupUrl, window.name);
    window.addEventListener("message", (event) => {
      console.log("event", event);
      updateLocation();
      popup?.close();
    });
  };

  const disconnectFromPoynt = () => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;
    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          poynt_payments: "",
        },
      },
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const connectButton = (
    <button
      className={`copper-button-confirm ${false && "text-transparent"}`}
      onClick={openPoyntAuthPopup}
      disabled={false}
    >
      Connect to Poynt
    </button>
  );

  const disconnectButton = (
    <Button onClick={disconnectFromPoynt}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const poyntAccountId = isConnectedToPoynt ? location.settings?.poynt_payments?.business_id : "";
  console.log("location_settings", location.settings);
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="space-y-4">
        <div className="text-responsive-sm pt-16 text-center w-full">
          {isConnectedToPoynt ? "You are connected to Poynt." : "Login with Poynt to connect your account."}
        </div>
        <div className="flex justify-center">
          <span className="text-responsive-sm pt-0.5 pr-2">Business ID:</span>
          <div>
            <input
              className="bg-gray-100 text-gray-500 text-xs md:text-sm; align-top text-center p-1 w-72 h-5"
              value={poyntAccountId}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">{isConnectedToPoynt ? disconnectButton : connectButton}</div>
    </div>
  );
};

export const PoyntTab = {
  name: "Poynt",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className=""
        width="112"
        alt="poynt-logo"
        src={`/img/payment_options/poynt-${active ? "active" : "inactive"}.svg`}
      />
    </>
  ),
  headerText: "Connect with Poynt",
  build: BuildPoyntOptions,
};

export default React.memo(BuildPoyntOptions);

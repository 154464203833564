import moment from "moment";
import React, { useState } from "react";
import { LogicBadge } from "../Badge";
import DataTable from "../DataTable";
import DeviceIcon from "../DeviceIcon";
import DeviceModal from "../modals/DeviceModal";
import Spinner from "../Spinner";
import WiFiStrengthIcon from "../WiFiStrengthIcon";

const DeviceLabel: React.FC<any> = ({ device }) => (
  <span className="flex flex-row items-center">{device.label || device.id.split("_")[1]}</span>
);

interface DevicesListProps {
  devices: Array<any>;
  onDeviceEdited: any;
  onDeviceRemoved: any;
  locations?: Array<any>;
  merchantId?: string;
  noItemsHeader?: string;
  noItemsBody?: string;
}

const DeviceList: React.FC<DevicesListProps> = ({
  devices,
  onDeviceEdited,
  onDeviceRemoved,
  merchantId,
  locations,
  noItemsHeader = "No devices found",
  noItemsBody = "No devices were found for this search",
}) => {
  const [selectedDevice, setSelectedDevice] = useState<any>();
  const columns = [
    {
      name: "icon",
      label: "",
      type: "icon",
    },
    {
      name: "id",
      label: "Label",
      type: "string",
    },
    {
      name: "serialNumber",
      label: "Serial Number",
      type: "string",
    },
    {
      name: "lastSeen",
      label: "Last Seen",
      type: "string",
    },
    {
      name: "wifiStrength",
      label: "Wi-Fi",
      type: "icon",
    },
    {
      name: "enabled",
      label: "Enabled",
      type: "boolean",
    },
  ];

  if (!devices) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }

  if (locations) {
    columns.splice(4, 0, {
      name: "location",
      label: "Location",
      type: "string",
    });
  }

  const columnsData = devices.map((device: any) => ({
    icon: <DeviceIcon device={device} />,
    id: DeviceLabel({ device }),
    serialNumber: device.serial_number,
    location: locations?.find((l: any) => l.id === device.location)?.name,
    lastSeen: moment(device.last_seen).fromNow(),
    wifiStrength:<WiFiStrengthIcon device={device}/> ,
    enabled: device.enabled ? 'Yes' : 'No',
    click: () => setSelectedDevice(device),
  }));

  return (
    <>
      <DeviceModal
        visible={!!selectedDevice}
        device={selectedDevice}
        onCancel={() => setSelectedDevice(null)}
        onSubmitSuccess={onDeviceEdited}
        onClickOutside={() => setSelectedDevice(null)}
        onRemove={onDeviceRemoved}
        onEdited={onDeviceEdited}
        merchantId={merchantId}
      />
      <DataTable
        data={columnsData}
        noItemsHeader={noItemsHeader}
        noItemsBody={noItemsBody}
        headerClasses="pl-responsive"
        tdClass="px-responsive"
        tdChildClass="py-2"
        disableFlexBetween={true}
        disableFullWidthCells={true}
        noBorderTopOnHeaders={true}
        roundCorners={true}
        columns={columns}
      />
    </>
  );
};

export default DeviceList;

import { API } from "aws-amplify";
import React from "react";
import removeEmptyObjectProps from "../../../util/removeEmptyObjectProps";
import Spinner from "../../Spinner";
import Toggle from "../../Toggle";
import QRSettingsComponent from "./QRSettingsComponent";
import NonCashComponent from "./NonCashComponent";
import TipField from "./TipField";
import PreAuthComponent from "./PreAuthComponent";

const BuildOtherSettings: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: string;
  register: any;
  reset?: any;
  handleSubmit: any;
  setValue: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
  isReseller: boolean;
  errors: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  setValue,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
  isReseller,
  errors,
}) => {
  const { settings } = location;

  const porcentageToNumeric = (number: any) => number && (Number.parseFloat(number) / 100).toFixed(3);

  const currencyToCents = (number: any) => number && (Number.parseFloat(number) * 100).toFixed(2);

  const onSave = (formData: any) => {
    console.log("formData", formData);
    const endpoint = `/locations/${location.id}`;
    let body = removeEmptyObjectProps({
      settings: {
        qr_payments: {
          enabled: formData.qr_payments_enabled,
          gateway: formData.qr_payments_gateway,
          redirect_url: formData.redirect_url,
        },
        cutoff_time: formData.cutoff_time,
        tips: {
          include_tax: formData.include_tax,
          quick_tip1: porcentageToNumeric(formData.quick_tip1),
          quick_tip2: porcentageToNumeric(formData.quick_tip2),
          quick_tip3: porcentageToNumeric(formData.quick_tip3),
          quick_tip4: porcentageToNumeric(formData.quick_tip4),
        },
        adjustments: {
          before_tax: formData.adjustment_before_tax,
          enabled: formData.adjustment_enabled,
          rate: porcentageToNumeric(formData.adjustment_rate),
        },
        pre_auth: {
          amount: currencyToCents(formData.pre_auth_amount),
          consent_text: formData.pre_auth_consent_text,
          enabled: formData.pre_auth_enabled,
        }
      }
    });
    
    if (formData.qr_method === "checkout") {
      body.settings.qr_payments.redirect_url = "";
      if (!formData.qr_payments_gateway) {
        setRequestError("Select a gateway");
        return;
      } else {
        let gatewayErr = "Enter payment gateway settings first";
        switch (formData.qr_payments_gateway) {
          case "bluefin":
            if (settings?.bluefin_payments?.connected) {
              gatewayErr = "";
            }
            break;

          case "express":
            if (settings?.express_payments?.connected) {
              gatewayErr = "";
            }
            break;

          case "stripe":
            if (settings?.stripe_payments?.connected) {
              gatewayErr = "";
            } else if (!isReseller) {
              gatewayErr = gatewayErr + ". Some settings might be needed by your reseller";
            }
          break;

          case "transit":
            if (settings?.transit_payments?.connected) {
              gatewayErr = "";
            }
          break;
        }
        if (gatewayErr) {
          setRequestError(gatewayErr);
          return;
        }
      }
    } else if (formData.qr_method === "redirect") {
      if (!formData.redirect_url) {
        setRequestError("Define a custom URL");
        return;
      }
    }

    setRequestError("");

    setIsSubmitting(true);

    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: body
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form className="h-full" onSubmit={handleSubmit(onSave)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-4">
          <QRSettingsComponent
            register={register}
            location={location}
            setValue={setValue}
          />

          {isReseller && (
            <NonCashComponent
              register={register}
              location={location}
              setValue={setValue}
            />
          )}

          <PreAuthComponent
            register={register}
            location={location}
            setValue={setValue}
          />

        </div>
        {!isReseller && (
          <div className="space-y-3">
            <label className="text-lg">
              Tip Options
            </label>
            <div>
              <div className="flex items-center pt-4">
                <Toggle
                  onChange={(value: boolean) => setValue("include_tax", value)}
                  value={settings?.tips?.include_tax}
                />
                <input
                  ref={register()}
                  id="include-tax"
                  name="include_tax"
                  type="hidden"
                  defaultValue={settings?.tips?.include_tax}
                />
                <span className="text-sm font-medium pl-2 text-gray-900">Include tax & fees</span>
              </div>
            </div>

            <TipField
              name="quick_tip4"
              label="Maximum"
              register={register}
              errors={errors.quick_tip4}
              defaultValue={settings?.tips?.quick_tip4 * 100}
            />
            <div className="flex w-full justify-around gap-4">
              <TipField
                name="quick_tip1"
                register={register}
                label="Min"
                extra="w-1/3"
                errors={errors.quick_tip1}
                defaultValue={settings?.tips?.quick_tip1 * 100}
              />
              <TipField
                name="quick_tip2"
                register={register}
                label="Med"
                extra="w-1/3"
                errors={errors.quick_tip2}
                defaultValue={settings?.tips?.quick_tip2 * 100}
              />
              <TipField
                name="quick_tip3"
                register={register}
                label="High"
                extra="w-1/3"
                errors={errors.quick_tip3}
                defaultValue={settings?.tips?.quick_tip3 * 100}
              />
            </div>
            {errors && (errors.quick_tip1 || errors.quick_tip2 || errors.quick_tip3 || errors.quick_tip4)
              && <span className="text-red-700 text-xs p-1 pt-4">Tips must be less than 30%</span>}
          </div>
        )}
        <div className="flex justify-between">
          {!!requestError ? <span className="label-error my-auto">{requestError}</span> : <div />}
          <button
            type="submit"
            className={`copper-button-confirm ${isSubmitting && "text-transparent"}`}
            disabled={false}
          >
            {isSubmitting && (
              <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0 md:px-1.5">
                <Spinner size={20} color="white"></Spinner>
              </div>
            )}
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export const OtherSettingTab = {
  name: "Other Settings",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <div className="flex">
      <img className="mr-2" width="18" alt="other-settings" src={`/img/payment_options/grid.svg`} />
      <div className="text-responsive-xl font-semibold opacity-70">Other Settings</div>
    </div>
  ),
  headerText: "Other Payment Settings",
  build: BuildOtherSettings,
};

export default React.memo(BuildOtherSettings);

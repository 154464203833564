export const locale: { [key: string]: any } = {
    US: {
        zipRegex: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        currency: "USD"
    },
    AU: {
        zipRegex: /^(\d{4})$/,
        currency: "AUD"
    },
}

export function getCurrency(countryCode: string) {
    return locale[countryCode].currency || ""
}

export function getZipCodeRegex(countryCode: string){
    return locale[countryCode].zipRegex ?? new RegExp("")
}
import { API } from "aws-amplify";
import { FunctionComponent, useEffect, useState } from "react";
import FormInputError from "../components/FormInputError";
import InfoCard from "../components/InfoCard";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import Main from "./containers/Main";

const Home: FunctionComponent = () => {
  const [inventory, setInventory] = useState<any>();
  const [error, setError] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    API.get("Conduit", `/accounts/${user?.account}/inventory`, {})
      .then((inventory) => {
        if (inventory) {
          setInventory({
            ...inventory,
            othersDevices: inventory.total_devices - inventory.total_cords,
            othersOffline: inventory.offline_devices - inventory.offline_cords,
          });
        }
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!inventory) {
    return (
      <Main>
        <div className="h-full">
          <div className="flex justify-around bg-form border rounded-md px-28 py-14 h-full">
            <div className="flex justify-center p-responsive">
              {!!error ? <FormInputError error={error} /> : <Spinner size={20} color="gray"></Spinner>}
            </div>
          </div>
        </div>
      </Main>
    );
  }

  const scaleColor = (value: number) => (value === 0) ? "text-copper-green" : "text-copper-orange";
  const scaleColorCords = (value: number) => (value === 0) ? "text-copper-green" : "text-error-red";

  return (
    <Main>
      <div className="h-full bg-gray-50 border rounded-md p-6">
        <div className="grid gap-12 md:grid-cols-3 grid-cols-1 justify-items-center ">
          {inventory.total_resellers && (
            <InfoCard data={inventory.total_resellers} description="Total Resellers"></InfoCard>
          )}
          <InfoCard data={inventory.total_merchants} description="Total Merchants"></InfoCard>
          <InfoCard data={inventory.total_locations} description="Total Locations"></InfoCard>
          <InfoCard
            data={inventory.total_devices}
            description="Total Devices"
            additionalInfo={
              <div className="flex flex-col justify-around gap-1 text-responsive-sm px-3">
                <div className="flex justify-between">
                  <span>{inventory.total_cords} Cords</span>
                  <span className={scaleColorCords(inventory.offline_cords)}>{inventory.offline_cords} offline</span>
                </div>
                {inventory.othersDevices > 0 && (
                  <div className="flex justify-between">
                    <span>{inventory.othersDevices} Others</span>
                    {inventory.othersOffline > 0 && (
                      <span className={scaleColor(inventory.othersOffline)}>{inventory.othersOffline} offline</span>
                    )}
                  </div>
                )}
              </div>
            }
          >
          </InfoCard>
          <InfoCard data={inventory.today_transactions} description="Transactions Today"></InfoCard>
        </div>
      </div>
    </Main>
  );
};

/*
!!inventory.offline_devices && <span className='label-error'>{inventory.offline_devices} offline</span>
total_cords: 158  / offline_cords
total_devices / offline_devices

offline_cords: 90
offline_devices: 172

total_cords: 158
total_devices: 265

total_locations: 28
total_merchants: 3
 */

export default Home;

import * as React from "react";
import logo from "../../logo.svg";

export const AuthBackdrop: React.FC<{ children: JSX.Element }> = ({ children }) => (
  <div className="flex flex-col justify-between p-16 w-full h-screen bg-auth">
    <div className="flex flex-col justify-end h-full">{copperLogo}</div>
    <div className="mx-auto mb-8 auth-dialogue-max-width w-full h-full">
      <div className="bg-white my-8 py-4 px-6 md:py-11 md:px-16 shadow-lg rounded-lg sm:px-10">{children}</div>
    </div>
    <div className="flex flex-col justify-start h-full -mt-6">
      <div className="flex justify-center">{footer}</div>
    </div>
  </div>
);

const copperLogo = <img className="mx-auto h-9 w-32" src={logo} alt="Workflow" />;

const footer = (
  <>
    <div className="auth-label text-gray-500">© Copper</div>
    <a className="auth-label-link px-5 md:px-12" href="mailto:support@usecopper.com">
      Contact
    </a>
    <a className="auth-label-link" href="https://usecopper.com/privacy">
      Privacy & Terms
    </a>
  </>
);

export default AuthBackdrop;

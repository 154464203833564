import amexIcon from "./amex.svg";
import discoverIcon from "./discover.svg";
import masterCardIcon from "./masterCard.svg";
import vipIcon from "./vip.svg";
import visaIcon from "./visa.svg";

export enum methodType {
  VISA = "visa",
  MASTERCARD = "mastercard",
  DISCOVER = "discover",
  AMEX = "amex",
  VIP = "vip",
}

const getIcon = (method: String) => {
  let icon;
  switch (method) {
    case methodType.VISA:
      icon = visaIcon;
      break;
    case methodType.MASTERCARD:
      icon = masterCardIcon;
      break;
    case methodType.DISCOVER:
      icon = discoverIcon;
      break;
    case methodType.AMEX:
      icon = amexIcon;
      break;
    case methodType.VIP:
      icon = vipIcon;
  }
  return icon;
};
export default getIcon;

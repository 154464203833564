import React, { FunctionComponent, useEffect } from "react";
import { useAuth } from "../../services/auth-context";
import Main from "../containers/Main";

const LogOut: FunctionComponent = () => {
  const { signOut } = useAuth();
  useEffect(() => signOut(), [signOut]);

  return (
    <Main>
      <div>Logouts...</div>
    </Main>
  );
};

export default LogOut;

export const objectToQueryString = (obj) => {
  const keys = Object.keys(obj);
  if (!keys.length) {
    return "";
  }
  return keys.reduce((acc, key) => [...acc, `${key}=${obj[key]}`], []).join("&");
};

export const queryStringToObject = (str) => {
  return str.split("&").reduce((acc, keyValuePair) => {
    if (!keyValuePair) {
      return acc;
    }

    const [key, value] = keyValuePair.split("=");
    if (!key || !value) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});
};

export default objectToQueryString;

import { API } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LogicBadge } from "../components/Badge";
import Button from "../components/Button";
import DataTable from "../components/DataTable";
import TransactionFilter, {
  dateRangeOption,
  defaultFilterSettings,
} from "../components/filters/transactions/TransactionFilter";
import MainHeader from "../components/MainHeader";
import PaginationControls from "../components/PaginationControls";
import PaymentField from "../components/paymentField";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import { getTransactionStatus } from "../util/copperLogic";
import { queryStringToObject } from "../util/objectToQueryString";
import Main from "./containers/Main";

const dbTimezone = "America/Los_Angeles";

const Transactions: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedRequestParams = queryStringToObject(location.search.replace("?", ""));
  const [transactions, setTransactions] = useState<any[] | any>(null);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [locations, setLocations] = useState<any[] | any>([]);
  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(defaultFilterSettings);
  const [filtering, setFiltering] = useState<any[] | any>(false);
  const [paramsFromUrl, setParamsFromUrl] = useState(parsedRequestParams);
  const { user, isOverrideUser } = useAuth();
  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    }).then((locations) => {
      if (locations.data) {
        setLocations(locations.data.map((location: any) => ({ id: location.id, name: location.display_name })));
      }
    });
  }, []);

  const getTransactions = () => {
    return transactions.map((transaction: any) => ({
      click: () => {
        history.push(`/transactions/${transaction.id}`);
      },
      amount: (
        <div className="flex flex-row -mr-4">
          <span className="w-14">{"$" + (transaction.amount / 100).toFixed(2)}</span>
          <span className="uppercase text-center text-gray-400 w-14 pr-2">{transaction.currency}</span>
          <div className="w-24">
            <LogicBadge
              value={getTransactionStatus(transaction)}
              full
              definition={{
                succeeded: "green",
                "pre-authorized": "green",
                refunded: "yellow",
                "partial refund": "yellow",
                voided: "yellow",
                "partial void": "yellow",
                declined: "red",
                failed: "red",
              }}
            />
          </div>
        </div>
      ),
      location: (
        <div className="w-1/2">
          {(locations.length > 0)
            && locations.find((location: { id: any; name: string }) => (location.id === transaction.location)).name}
        </div>
      ),
      customer: (
        <span className="capitalize">
          {(!!transaction?.customer?.name
            && transaction.customer.name.split("/").reverse().join(" ").trim().toLowerCase())
            || "Unknown"}
        </span>
      ),
      paymentMethod: (
        <PaymentField
          type={transaction.payment_method?.type === "vip" ? "vip" : transaction.payment_method?.card?.brand}
          last4={transaction.payment_method?.card?.last4
            || transaction.payment_method?.bank_account?.account_number.replace(/\*/g, "")}
        />
      ),
      date: moment(transaction.created).format("MMM DD, h:mm A"),
    }));
  };

  const componentState = {
    isShowingFilters,
    setIsShowingFilters,
    locations,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
    setFiltering,
  };

  if (filtering) {
    window.history.replaceState("", "", `?`);
    setTimeout(() => {
      setTransactions(null);
      setFiltering(false);
    }, 200);
  }

  return (
    <Main>
      <div>
        <div className="p-4 flex justify-between">
          <MainHeader>Transactions</MainHeader>
          <Button onClick={() => setIsShowingFilters(!isShowingFilters)} icon="ion-android-funnel">
            Filter
          </Button>
          <TransactionFilter {...componentState} />
        </div>
        {!!transactions && !filtering
          ? (
            <DataTable
              data={getTransactions()}
              noItemsHeader="No transactions yet"
              noItemsBody="When a transaction is made, it will show here."
              headerClasses="pl-4"
              tdClass="px-4 py-2"
              noBorderTopOnHeaders={true}
              disableFullWidthCells={true}
              disableFlexBetween={true}
              columns={[
                {
                  name: "amount",
                  label: "Amount",
                  type: "currency",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "location",
                  label: "Location",
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "customer",
                  label: "Customer",
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "paymentMethod",
                  label: "Payment Method",
                  type: "component",
                  headerClass: "uppercase w-44",
                },
                {
                  name: "date",
                  label: "Date",
                  type: "datetime",
                  headerClass: "uppercase w-12",
                },
              ]}
            />
          )
          : (
            <div className="flex justify-center p-responsive">
              <Spinner size={20} color="gray"></Spinner>
            </div>
          )}
      </div>
      {!filtering && (
        <PaginationControls
          endpoint="/transactions"
          onListChange={(transactions: any) => {
            setParamsFromUrl({});
            setTransactions(transactions);
          }}
          initialPage={(!!paramsFromUrl?.page && parseInt(paramsFromUrl.page)) || 1}
          requestParameters={filterSettingsToRequestParameters(filterSettings, paramsFromUrl)}
          hide={!transactions}
        />
      )}
    </Main>
  );
};

const filterSettingsToRequestParameters = (filterSettings: any, fromUrl: any) => {
  let requestParameters = fromUrl;

  if (filterSettings.location.enabled && !!filterSettings.location.id) {
    requestParameters = {
      ...requestParameters,
      location: filterSettings.location.id,
    };
  }

  if (filterSettings.dateRange.enabled) {
    requestParameters = {
      ...requestParameters,
      ...buildDateRangeFilters(filterSettings.dateRange),
    };
  }

  if (filterSettings.cardNumber.enabled && !!filterSettings.cardNumber.number) {
    requestParameters = {
      ...requestParameters,
      search_query: filterSettings.cardNumber.number,
    };
  }

  if (filterSettings.onlyPreAuth.enabled) {
    requestParameters = {
      ...requestParameters,
      only_pre_auth: filterSettings.onlyPreAuth.enabled
    };
  }
  return requestParameters;
};

export const buildDateRangeFilters = (dateRangeSettings: any, field: string = "created") => {
  const [gte, lte, lt] = [`${field}[gte]`, `${field}[lte]`, `${field}[lt]`];
  const buildInTheLast = () => {
    const now = moment().tz(dbTimezone);
    const substractOptions = { [dateRangeSettings.period]: dateRangeSettings.amount };
    const filterDate = now.subtract(substractOptions);
    return {
      [gte]: filterDate.valueOf() / 1000,
    };
  };

  const buildIsEqualTo = () => {
    const inputDate = dateRangeSettings.startDate.toISOString();
    const isEqualToDate = moment(inputDate);
    const startDateTime = isEqualToDate.clone().tz(dbTimezone).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const endDateTime = isEqualToDate.clone().tz(dbTimezone).set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return {
      [gte]: startDateTime.valueOf() / 1000,
      [lte]: endDateTime.valueOf() / 1000,
    };
  };

  const buildInBetween = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const inputEndDate = dateRangeSettings.endDate.toISOString();
    const startDateTime = moment(inputStartDate).tz(dbTimezone).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const endDateTime = moment(inputEndDate).clone().tz(dbTimezone).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    return {
      [gte]: startDateTime.valueOf() / 1000,
      [lte]: endDateTime.valueOf() / 1000,
    };
  };

  const buildIsAfter = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const startDateTime = moment(inputStartDate)
      .tz(dbTimezone)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .add({ day: 1 });

    return {
      [gte]: startDateTime.valueOf() / 1000,
    };
  };

  const buildIsOnOrAfter = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const startDateTime = moment(inputStartDate).tz(dbTimezone).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    return {
      [gte]: startDateTime.valueOf() / 1000,
    };
  };

  const buildIsBefore = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const startDateTime = moment(inputStartDate).tz(dbTimezone).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    return {
      [lt]: startDateTime.valueOf() / 1000,
    };
  };

  const buildIsBeforeOrOn = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const startDateTime = moment(inputStartDate).tz(dbTimezone).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    return {
      [lte]: startDateTime.valueOf() / 1000,
    };
  };

  return (
    {
      [dateRangeOption.inTheLast]: buildInTheLast,
      [dateRangeOption.isEqualTo]: buildIsEqualTo,
      [dateRangeOption.inBetween]: buildInBetween,
      [dateRangeOption.isAfter]: buildIsAfter,
      [dateRangeOption.isOnOrAfter]: buildIsOnOrAfter,
      [dateRangeOption.isBefore]: buildIsBefore,
      [dateRangeOption.isBeforeOrOn]: buildIsBeforeOrOn,
    }[dateRangeSettings.type]
    || (() => {
      alert(`Date Filter Type Not Found: ${dateRangeSettings.type}`);
      return {};
    })
  )();
};

export default Transactions;

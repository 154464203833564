import { API } from "aws-amplify";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import ModalForm from "../ModalForm";

const RefundModal: React.FC<{
  transactionId: string;
  refundableAmount: number;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  merchantId: string;
  currency: string;
}> = ({ transactionId, refundableAmount, onCancel, onSubmitSuccess, onClickOutside, visible, merchantId,currency }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [reasonText, setReasonText] = useState("");

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    const endpoint = `/refunds`;
    API.post("Conduit", endpoint, {
      body: {
        transaction: transactionId,
        amount: (formData.amount.replace("$", "") * 100).toFixed(),
        reason: formData.reason === "other" ? formData.reason_text : formData.reason,
      },
    })
      .then((res) => {
        if (res.status === "failed") {
          setRequestError("Refund failed");
        } else {
          onSubmitSuccess();
        }
      })
      .catch((err) => {
        console.log("refund err", err.response);
        setRequestError(err?.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  const formErrorDisplay = (err: any) =>
    err
    && err.type === "required" && <div className="label-error-sm pl-1.5 label-error -mb-4 md:-mb-3">{err.message}</div>;

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title="Refund Payment"
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
        confirmButtonText="Refund"
      >
        <div className="">
          <div className="flex">
            <img className="-mt-0.5 mr-2" width="18" alt="info icon" src={`/img/info.svg`} />
            <span className="text-responsive-sm font-medium">
              Refunds take 5-10 days to appear on a customer’s statement.
            </span>
          </div>
          <div className="flex flex-row mx-auto pb-7 pt-8 w-96">
            <label className="text-responsive-base my-auto w-16">Refund</label>
            <div className="flex flex-col">
              <input
                ref={register({ required: "Amount required" })}
                id="amount"
                name="amount"
                defaultValue={(refundableAmount / 100).toFixed(2)} // this is a temporal hack because poynt don't allow us to do multiple partial refunds
                className="copper-input pr-12"
              />
              {formErrorDisplay(errors?.amount)}
            </div>
            <div className="w-0 h-0 overflow-visible relative top-2 right-11 opacity-40">{currency}</div>
          </div>
          <div className="flex flex-row mx-auto w-96">
            <label className="text-responsive-base mt-2 pr-4">Reason</label>
            <div className="flex flex-col mx-auto w-96">
              <div className="mb-4">
                <select
                  ref={register({ required: "Reason required" })}
                  id="reason"
                  name="reason"
                  className="copper-input"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                >
                  <option value="" disabled={true}>
                    Select a reason
                  </option>
                  <option value="duplicate">Duplicate</option>
                  <option value="fraudulent">Fraudulent</option>
                  <option value="requested_by_customer">Requested by customer</option>
                  <option value="other">Other</option>
                </select>
                {formErrorDisplay(errors?.reason)}
              </div>
              <div className={`flex flex-col ${selectedReason !== "other" ? "hidden" : ""}`}>
                <textarea
                  ref={register({
                    validate: () => selectedReason !== "other" || !!reasonText,
                  })}
                  id="reason_text"
                  name="reason_text"
                  className={`copper-input text-responsive-xs max-h-96`}
                  placeholder="Add a reason for this refund."
                  value={reasonText}
                  onChange={(e) => setReasonText(e.target.value)}
                />
                <span
                  className={`text-tiny md:text-xs pt-2 pl-1 ${
                    !!errors?.reason_text ? "label-error" : "text-gray-900 opacity-50"
                  }`}
                >
                  A note is required when a provided reason isn’t selected.
                </span>
              </div>
            </div>
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
};

export default RefundModal;

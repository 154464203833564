import React from "react";
import { useHistory } from "react-router-dom";
import AuthBackdrop from "../../components/auth/AuthBackdrop";

const ResetSuccess: React.FC = () => {
  const history = useHistory();

  const onContinue = () => history.push({ pathname: "/" });

  return (
    <AuthBackdrop>
      <>
        <p className="text-gray-700 text-responsive-xl">You’ve successfully changed your password</p>

        <div className="pt-10">
          <button className="copper-button-confirm-full" onClick={onContinue}>
            Continue to Login
          </button>
        </div>
      </>
    </AuthBackdrop>
  );
};

export default ResetSuccess;

import { Popover } from "@headlessui/react";
import { API } from "aws-amplify";
import { schemeTableau10 as schemeCategory } from "d3-scale-chromatic";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Toggle from "../components/Toggle";
import { useAuth } from "../services/auth-context";
import { querySales } from "../util/twispApi";
import Main from "./containers/Main";
import { categorizeSales, ItemLabels } from "./Labels";
import {getCurrency} from "../util/Locale";
import {renderAmount, renderAmountThousands} from "../components/utils/util";

const today = () => moment();
const colors: { [index: string]: string } = {
  legend: "#5E6065",
  grid: "#E5E8EF",
  grey: "#828282",
  red: "#EF8368",
  green: "#27AE60",
  tooltip: "#263446",
};

const trendDown = (
  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0417 9.29168L13.2417 8.09168L9.17506 4.02502L6.4334 6.76668C6.1084 7.09168 5.5834 7.09168 5.2584 6.76668L0.258398 1.75835C-0.0666016 1.43335 -0.0666016 0.90835 0.258398 0.58335C0.583398 0.25835 1.1084 0.25835 1.4334 0.58335L5.84173 5.00002L8.5834 2.25835C8.9084 1.93335 9.4334 1.93335 9.7584 2.25835L14.4167 6.90835L15.6167 5.70835C15.8751 5.45002 16.3251 5.63335 16.3251 6.00002V9.57502C16.3251 9.80835 16.1417 9.99168 15.9084 9.99168H12.3334C11.9667 10 11.7834 9.55002 12.0417 9.29168Z"
      fill="currentColor"
    />
  </svg>
);

const trendUp = (
  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0417 0.708333L13.2417 1.90833L9.17506 5.975L6.4334 3.23333C6.1084 2.90833 5.5834 2.90833 5.2584 3.23333L0.258398 8.24167C-0.0666015 8.56667 -0.0666015 9.09167 0.258398 9.41667C0.583398 9.74167 1.1084 9.74167 1.4334 9.41667L5.84173 5L8.5834 7.74167C8.9084 8.06667 9.4334 8.06667 9.7584 7.74167L14.4167 3.09167L15.6167 4.29167C15.8751 4.55 16.3251 4.36667 16.3251 4V0.416667C16.3334 0.183333 16.1501 0 15.9167 0H12.3417C11.9667 0 11.7834 0.45 12.0417 0.708333Z"
      fill="currentColor"
    />
  </svg>
);

const trendSame = (
  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line strokeWidth="2" fill="none" stroke="currentColor" x1="4" y1="3" x2="12" y2="3"></line>
    <line strokeWidth="2" fill="none" stroke="currentColor" x1="4" y1="7" x2="12" y2="7"></line>
  </svg>
);

function LocationPicker({
  locations,
  selected,
  setSelected,
}: {
  locations: any[];
  selected: any;
  setSelected: any;
}) {
  return (
    <select
      className="inline-block pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      onChange={event => setSelected(event.target.value || null)}
      value={selected?.id}
    >
      <option value="">All locations</option>
      {locations?.map((location, i) => <option key={i} value={location.id}>{location.display_name}</option>)}
    </select>
  );
}

function TimePeriodPicker({
  periods,
  period,
  setPeriod,
}: {
  periods: any[];
  period: any;
  setPeriod: any;
}) {
  const PeriodButton = ({ children, close, value }: { children: any; close: any; value: any }) => {
    const colorsClassName = value === period.value
      ? "text-white bg-gray-800 border-gray-800 hover:bg-gray-900"
      : "text-gray-700 bg-white border-gray-300 hover:bg-gray-50";
    return (
      <button
        className={`${colorsClassName} inline-flex items-center px-2.5 py-1.5 border shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        onClick={() => {
          setPeriod(value);
          close();
        }}
      >
        {children}
      </button>
    );
  };

  return (
    <Popover as="div" className="relative inline-block text-left">
      <div>
        <Popover.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="font-bold mr-1">{period.label}</span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            x-description="Heroicon name: solid/chevron-down"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            >
            </path>
          </svg>
        </Popover.Button>
      </div>
      <Popover.Panel className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        {({ close }) => (
          <div className="divide-y divide-gray-200">
            <div className="flex flex-col space-y-2 p-2">
              {periods.map((period, i) => (
                <PeriodButton key={i} value={period.value} close={close}>{period.label}</PeriodButton>
              ))}
              <input
                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onChange={event => setPeriod(event.target.value)}
                onBlur={() => close()}
                type="date"
                value={period.value}
              />
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}

function ComparePicker({
  period,
  compare,
  setCompare,
}: {
  period: any;
  compare: any;
  setCompare: any;
}) {
  const comparisons = period.compare || [];

  const CompareButton = (
    { children, close, value, ...rest }:
      & { children: any; close: any; value: any }
      & React.HTMLAttributes<HTMLButtonElement>,
  ) => {
    const colorsClassName = value === compare
      ? "text-white bg-gray-800 border-gray-800 hover:bg-gray-900"
      : "text-gray-700 bg-white border-gray-300 hover:bg-gray-50";
    return (
      <button
        className={`${colorsClassName} inline-flex items-center px-2.5 py-1.5 border shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        onClick={() => {
          setCompare(value);
          close();
        }}
        {...rest}
      >
        {children}
      </button>
    );
  };

  return (
    <Popover as="div" className="relative inline-block text-left">
      <div>
        <Popover.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {compare
            ? (
              <>
                <span className="font-bold mr-1">Compare:</span> <span>{compare?.label}</span>
              </>
            )
            : <span className="font-bold mr-1">Compare</span>}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            x-description="Heroicon name: solid/chevron-down"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            >
            </path>
          </svg>
        </Popover.Button>
      </div>
      <Popover.Panel className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        {({ close }) => (
          <div className="divide-y divide-gray-200">
            <div className="flex flex-col space-y-2 p-2">
              {compare && <CompareButton value={null} close={close}>Remove comparison</CompareButton>}
              {comparisons.map((comparison: any, i: number) => (
                <CompareButton key={i} value={comparison.value} close={close}>
                  {comparison.label}
                </CompareButton>
              ))}
              {!period.value.includes("W") && (
                <input
                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onChange={event => setCompare(event.target.value)}
                  onBlur={() => close()}
                  type="date"
                  value={compare || ""}
                />
              )}
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Plus: FunctionComponent = () => {
  const history = useHistory();
  const query = useQuery();

  const setParams = (params: any) => {
    const newQuery = new URLSearchParams(query);
    for (const [key, value] of Object.entries(params)) {
      newQuery.delete(key);
      if (typeof value === "string" || value === true) {
        newQuery.set(key, String(value));
      }
    }
    history.push({ search: newQuery.toString() });
  };

  const setParam = (key: string, value?: string) => setParams({ [key]: value });

  const cumulative = query.has("cumulative");
  const setCumulative = (v: any) => setParam("cumulative", v);

  const compare = query.get("compare") as string;
  const setCompare = (v: any) => setParam("compare", v);

  const periods = [
    {
      label: "Today",
      value: today().format("YYYY-MM-DD"),
      compare: [
        { label: "Same day last week", value: today().subtract(1, "week").format("YYYY-MM-DD") },
      ],
    },
    {
      label: "Yesterday",
      value: today().subtract(1, "days").format("YYYY-MM-DD"),
      compare: [
        { label: "Same day last week", value: today().subtract({ weeks: 1, days: 1 }).format("YYYY-MM-DD") },
      ],
    },
    {
      label: "This week",
      value: today().format("YYYY-[W]WW"),
      compare: [
        { label: "Last week", value: today().subtract(1, "week").format("YYYY-[W]WW") },
      ],
    },
    {
      label: "Last week",
      value: today().subtract(1, "week").format("YYYY-[W]WW"),
      compare: [
        { label: "Week before last", value: today().subtract(2, "weeks").format("YYYY-[W]WW") },
      ],
    },
  ];

  const period = query.get("period") as string || DateTime.now().toISODate();
  const setPeriod = (v: any) => {
    setParams({
      period: v,
      compare: null,
    });
  };

  const thePeriod = periods.find(p => p.value === period) || (period
    ? {
      value: period,
      label: moment(period).format("LL"),
      compare: [],
    }
    : periods[0]);
  const theCompare = thePeriod.compare?.find(c => c.value === compare) || (compare && {
    value: compare,
    label: compare.includes("W")
      ? `${moment(compare).startOf("isoWeek").format("D MMM")} – ${
        moment(compare).endOf("isoWeek").format("D MMM YYYY")
      }`
      : moment(compare).format("LL"),
  });

  const { user } = useAuth();
  const [locations, setLocations] = useState<any>(null);
  const [sales, setSales] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const account = useMemo(() => user!.accountData, [user]);
  const location = locations?.find((l: any) => l.id === query.get("location"));
  const setLocation = (v: any) => setParam("location", v);
  const country = location ? location.address.country : account.address.country;
  const currency = getCurrency(country);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    })
      .then((locations) =>
        setLocations(locations.data.sort((a: any, b: any) => a.display_name.localeCompare(b.display_name)))
      )
      .catch((error) => setError(error.response?.data?.error?.message || error.message));
  }, []);

  const refresh = useCallback(() => {
    querySales({ account: account.id, location: location?.id, period, compare })
      .then(sales => {
        setError(null);
        setSales(sales);
      })
      .catch(error => {
        if (!error.isAxiosError) {
          throw error;
        }

        setError(error);
        setSales(null);
      });
  }, [account, location, period, compare]);

  useEffect(() => {
    if (!locations) {
      return;
    }

    refresh();
  }, [location, locations, refresh]);

  const hasLoaded = !error && !!sales;
  const hasSales = sales?.intervals?.length > 0;

  const topSellers = sales?.topSellers.slice(0, 10);
  const topServers = sales?.topServers?.slice(0, 10);
  const topLocations = sales?.topLocations?.slice(0, 10);

  const [labels, setLabels] = useState<ItemLabels>({});

  useEffect(() => {
    API.get("Conduit", `/accounts/${account.id}`, {}).then((account: any) => {
      setLabels(account.mcp_labels || {});
    });
  }, [account]);

  const { categorizedSales } = categorizeSales(
    labels,
    sales?.topSellers.map((item: any) => ({
      label: item.label,
      sales: item.period.amount,
    })),
  );

  return (
    <Main>
      <div className="relative z-10 flex justify-end mb-2 items-center space-x-2">
        <button
          className="inline-block px-3 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onClick={refresh}
        >
          Refresh
        </button>
        <LocationPicker
          locations={locations}
          selected={location}
          setSelected={(location: any) => setLocation(location)}
        />
        <TimePeriodPicker
          periods={periods}
          period={thePeriod}
          setPeriod={(period: any) => setPeriod(period)}
        />
        <ComparePicker period={thePeriod} compare={theCompare} setCompare={(compare: any) => setCompare(compare)} />
      </div>
      {error && (
        <div>
          Unexpected error fetching data.
        </div>
      )}
      {hasLoaded && !hasSales && (
        <div>
          No data within specified time period.
        </div>
      )}
      {hasLoaded && hasSales && (
        <div>
          <div className="grid grid-cols-2 content-start gap-6 bg-gray-50 border rounded-md p-6">
            <div className="col-span-2 bg-white border rounded-md shadow-lg p-6">
              <div className="mb-6 flex justify-between">
                <h2 className="font-bold">Sales ({period.includes("W") ? "Daily" : "Hourly"})</h2>
                <span className="font-mono font-semibold">{renderAmount(sales?.timePeriodRunningTotal, currency)}</span>
              </div>

              <SalesChart
                compare={theCompare}
                period={thePeriod}
                cumulative={cumulative}
                currency = {currency}
                data={sales?.intervals || []}
              />

              <div className="flex place-content-between mt-8 mr-5">
                <div className="ml-16 flex space-x-8">
                  <div className="flex space-x-2 items-center">
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <line strokeWidth="2" fill="none" stroke={colors.red} x1="0" y1="16" x2="32" y2="16"></line>
                    </svg>
                    <span>
                      {thePeriod.label}
                    </span>
                  </div>
                  {theCompare && (
                    <div className="flex space-x-2 items-center">
                      <svg width="32" height="32" viewBox="0 0 32 32">
                        <line strokeWidth="2" fill="none" stroke={colors.green} x1="0" y1="16" x2="32" y2="16"></line>
                      </svg>
                      <span>
                        {theCompare.label}
                      </span>
                    </div>
                  )}
                </div>
                <label className="flex items-center">
                  <Toggle onChange={(value: boolean) => setCumulative(value)} value={cumulative} />
                  <span className="ml-2">Cumulative</span>
                </label>
              </div>
            </div>

            {topSellers
              && (
                <div className="col-span-1 bg-white border rounded-md shadow-lg py-2">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="w-full px-8 py-2 text-left text-lg font-bold" scope="col">
                          Top Sellers
                        </th>
                        <th scope="col" />
                        <th
                          className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                          scope="col"
                          // onClick={() => setTopSellersOrder(topSellersOrder !== "quantity" ? "quantity" : null)}
                        >
                          Total
                        </th>
                        <th
                          className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                          scope="col"
                          // onClick={() => setTopSellersOrder(topSellersOrder !== "quantity" ? "quantity" : null)}
                        >
                          Qty.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSellers.map((item: any) => (
                        <tr key={item.label}>
                          <td className="px-8 py-1 whitespace-nowrap text-base text-gray-light">
                            {item.label}
                          </td>
                          <td
                            className="py-1 pr-2"
                            title={renderAmount(item.compare?.amount, currency)}
                          >
                            {!!item.compare?.amount && item.compare?.amount < item.period.amount
                              && <span className="text-copper-green">{trendUp}</span>}
                            {item.compare?.amount === item.period.amount
                              && <span className="text-copper-green">{trendSame}</span>}
                            {item.compare?.amount > item.period.amount && (
                              <span className="text-copper-orange">{trendDown}</span>
                            )}
                          </td>
                          <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                            {renderAmount(item.period.amount, currency)}
                          </td>
                          <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                            {item.period.quantity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            {topServers
              && (
                <div className="col-span-1 bg-white border rounded-md shadow-lg py-2">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th
                          className="w-full px-8 py-2 text-left text-lg font-bold"
                          scope="col"
                        >
                          Top Servers
                        </th>
                        <th scope="col" />
                        <th
                          className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                          scope="col"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topServers.map((server: any) => (
                        <tr key={server.server}>
                          <td className="px-8 py-1 whitespace-nowrap text-base text-gray-light">
                            {titleCase(server.server)}
                          </td>
                          <td
                            className="py-1 pr-2"
                            title={renderAmount(server.compare?.amount, currency)}
                          >
                            {!!server.compare?.amount && server.compare?.amount < server.period.amount
                              && <span className="text-copper-green">{trendUp}</span>}
                            {server.compare?.amount === server.period.amount
                              && (
                                <span className="font-bold px-1 text-copper-green select-none">
                                  =
                                </span>
                              )}
                            {server.compare?.amount > server.period.amount && (
                              <span className="text-copper-orange">{trendDown}</span>
                            )}
                          </td>
                          <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                            {renderAmount(server.period.amount, currency)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            {topLocations
              && (
                <div className="col-span-1 bg-white border rounded-md shadow-lg py-2">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th
                          className="w-full px-8 py-2 text-left text-lg font-bold"
                          scope="col"
                        >
                          Top Locations
                        </th>
                        <th scope="col" />
                        <th
                          className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                          scope="col"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topLocations.map((topLocation: any) => {
                        const locationId = topLocation.location
                        const location = locations.find((l: any) => l.id === locationId)
                        return (
                          <tr key={topLocation.location}>
                            <td className="px-8 py-1 whitespace-nowrap text-base text-gray-light">
                              {titleCase(location.display_name)}
                            </td>
                            <td
                              className="py-1 pr-2"
                              title={renderAmount(topLocation.compare?.amount, currency)}
                            >
                              {!!topLocation.compare?.amount && topLocation.compare?.amount < topLocation.period.amount
                                && <span className="text-copper-green">{trendUp}</span>}
                              {topLocation.compare?.amount === topLocation.period.amount
                                && (
                                  <span className="font-bold px-1 text-copper-green select-none">
                                    =
                                  </span>
                                )}
                              {topLocation.compare?.amount > topLocation.period.amount && (
                                <span className="text-copper-orange">{trendDown}</span>
                              )}
                            </td>
                            <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                              {renderAmount(topLocation.period.amount, currency)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}

            <div className="bg-white border rounded-md shadow-lg py-8">
              <CategoryChart data={categorizedSales} currency={currency} />
            </div>

            <div className="col-span-1 bg-white border rounded-md shadow-lg py-2">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="w-full px-8 py-2 text-left text-lg font-bold" scope="col">
                      Item Category
                    </th>
                    {/* <th scope="col" /> */}
                    <th
                      className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                      scope="col"
                    >
                      Percentage
                    </th>
                    <th
                      className="pr-8 py-2 text-base font-bold space-x-2 text-right"
                      scope="col"
                    >
                      Volume
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categorizedSales.map((category: any) => (
                    <tr key={category.name}>
                      <td className="px-8 py-1 whitespace-nowrap text-base text-gray-light">
                        {category.name}
                      </td>
                      {
                        /* <td
                            className="py-1 pr-2"
                            title={topSellersOrder === "amount"
                              ? renderAmountRounded(category.compare?.amount)
                              : category.compare?.quantity}
                          >
                            {!!category.compare?.amount && category.compare?.amount < category.period.amount
                              && <span className="text-copper-green">{trendUp}</span>}
                            {category.compare?.amount === category.period.amount
                              && <span className="text-copper-green">{trendSame}</span>}
                            {category.compare?.amount > category.period.amount && (
                              <span className="text-copper-orange">{trendDown}</span>
                            )}
                          </td> */
                      }
                      <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                        {(category.value / sales.timePeriodRunningTotal * 100).toFixed()}%
                      </td>
                      <td className="pr-8 py-1 whitespace-nowrap text-base font-mono text-right">
                        {renderAmount(category.value, currency)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default Plus;

function titleCase(text: string) {
  return text[0].toUpperCase() + text.slice(1).toLocaleLowerCase();
}


interface SalesChartProps {
  period: any;
  compare?: any;
  cumulative?: boolean;
  currency: string,
  data: {
    interval: string;
    timePeriod?: { total: number };
    comparePeriod?: { total: number };
  }[];
}

const SalesChart: FunctionComponent<SalesChartProps> = ({
  compare,
  period,
  cumulative,
  currency,
  data,
}) => {
  const grid = <CartesianGrid strokeDasharray="2 3" stroke={colors.grid} vertical={false} />;
  const xAxis = (
    <XAxis
      dataKey="interval"
      stroke={colors.grid}
      tick={{ fill: colors.grey }}
      tickLine={false}
      tickMargin={10}
    />
  );
  const yAxis = (
    <YAxis
      tickLine={false}
      stroke={colors.grid}
      tick={{ fill: colors.grey }}
      tickFormatter={renderAmountThousands}
      tickMargin={10}
    />
  );
  const tooltip = (
    <Tooltip
      // content={<CustomTooltip />}
      cursor={false}
      formatter={(value: number) => renderAmount(value, currency)}
      separator=" "
    />
  );

  const periodName = period.label;
  const comparisonName = compare?.label;

  return (
    <div className="w-full h-56 text-base">
      <ResponsiveContainer>
        {cumulative
          ? (
            <LineChart data={data} margin={{ right: 20 }}>
              {grid}
              {xAxis}
              {yAxis}
              {tooltip}
              <Line
                dataKey="timePeriodRunningTotal"
                dot={false}
                stroke={colors.red}
                strokeWidth={2}
                type="monotone"
                name={period.label}
              />
              {compare && (
                <Line
                  dataKey="comparePeriodRunningTotal"
                  dot={false}
                  stroke={colors.green}
                  strokeWidth={2}
                  type="monotone"
                  name={comparisonName}
                />
              )}
            </LineChart>
          )
          : (
            <BarChart barGap={0} data={data} margin={{ right: 20 }} barSize={30}>
              {grid}
              {xAxis}
              {yAxis}
              {tooltip}
              <Bar
                dataKey="timePeriod.amount"
                fill={colors.red}
                width={16}
                name={periodName}
                stackId={1}
              />
              {compare && (
                <Bar
                  dataKey="comparePeriod.amount"
                  fill={colors.green}
                  width={16}
                  name={comparisonName}
                  stackId={0}
                />
              )}
            </BarChart>
          )}
      </ResponsiveContainer>
    </div>
  );
};

const CategoryChart: any = ({
  data,
  currency,
}: {
  data: { name: string; value: number }[];
  currency: string
}) => {
  return (
    <div className="w-full h-72 text-base">
      <ResponsiveContainer>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label={(value: any) => `${(value.percent * 100).toFixed()}%`}
            labelLine={true}
          >
            {data.map((_, index) => <Cell key={`cell-${index}`} fill={schemeCategory[index]} />)}
          </Pie>
          <Tooltip formatter={(value: any, name: any) => [renderAmount(value,currency), name]} separator=": " />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

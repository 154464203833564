import React, {useState} from "react";
import AddressFields from "./AddressFields";
import FormInput from "./FormInput";
import LeftLabeledContainer from "./LeftLabeledContainer";
import {getCurrency} from "../util/Locale";

const NameAddressForm: React.FC<{
  register: any;
  errors: any;
  display_name?: string;
  name?: string;
  email?: string;
  address: any;
  usesDisplayName?: boolean;
  showEmail?: boolean;
  nameLabel?: string;
  addressLabel?: string;
  preselectedCountry?: any;
}> = ({
  register,
  errors,
  display_name,
  name,
  email,
  address,
  usesDisplayName,
  showEmail,
  nameLabel,
  addressLabel,
  preselectedCountry
}) => {
  return (
      <>
        <LeftLabeledContainer name={nameLabel || "Name"}>
          {usesDisplayName
              ? (
                  <FormInput
                      formRegisterRef={register({required: "Name required"})}
                      id="display_name"
                      defaultValue={display_name ?? ""}
                      error={errors?.display_name}
                  />
              )
              : (
                  <FormInput
                      formRegisterRef={register({required: "Name required"})}
                      id="name"
                      defaultValue={name ?? ""}
                      error={errors?.name}
                  />
              )}
        </LeftLabeledContainer>
        {showEmail && (
            <LeftLabeledContainer name="Email">
              <FormInput
                  formRegisterRef={register({required: "Email required"})}
                  id="email"
                  defaultValue={email ?? ""}
                  error={errors?.email}
              />
            </LeftLabeledContainer>
        )}
          <AddressFields {...{
            register, errors, defaultAddress: address, preselectedCountry: preselectedCountry
          }} />
      </>
  );
};

export default NameAddressForm;

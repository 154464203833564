import React, { useState } from "react";
import Toggle from "../../Toggle";

const PreAuthComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
}> = ({ location, setValue, register, isHide, isDisabled = false }) => {
  const { enabled, amount, consent_text } = location.settings.pre_auth;

  const [toggleEnabled, setToggleEnabled] = useState(enabled);

  return (isHide !== true)
    ? (
      <div>
        <label className="text-lg">
          Pre-authorization
        </label>
        <div className="flex h-10 items-center pt-4">
          <Toggle
            onChange={() => {
              const newValue = !toggleEnabled;
              setToggleEnabled(newValue);
              setValue("pre_auth_enabled", newValue);
            }}
            value={toggleEnabled}
            isDisabled={isDisabled}
          />
          <span className="w-full text-sm fsont-medium pl-4 text-gray-900">
            Enable pre-authorization
          </span>
          <input
            id="pre_auth-enabled"
            name="pre_auth_enabled"
            value={toggleEnabled}
            ref={register()}
            type={"hidden"}
          />
          <div className={!toggleEnabled ? "hidden" : "flex items-center align-middle"}>
            $ <input
              id="pre_auth-amount"
              name="pre_auth_amount"
              ref={register()}
              className="copper-input w-16 mr-2"
              defaultValue={(amount / 100).toFixed(2)}
            />
          </div>
        </div>
        <div className={!toggleEnabled ? "hidden" : "flex h-10 items-center pt-2"}>
          <span className="text-base">Pre-authorization display message</span>
        </div>
        <div className={!toggleEnabled ? "hidden" : "flex items-center"}>
          <textarea
            cols={50}
            id="pre_auth-consent-text"
            name="pre_auth_consent_text"
            className={`copper-input text-responsive-xs max-h-96`}
            defaultValue={consent_text ? consent_text : "* The tab will be closed with the actual spending.The remaining amount will go back to your card.\r\n* If the tab remains open after business hours, it will be closed using the card on file, with a 15% gratuity added."}
            ref={register()}
          />
        </div>
      </div>
    )
    : null;
};

export default React.memo(PreAuthComponent);

import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import {
  Devices,
  Home,
  Labels,
  Location,
  Locations,
  LoginScreen,
  Logout,
  Merchant,
  Merchants,
  Plus,
  ProfileSettings,
  Reseller,
  Resellers,
  SetPassword,
  Settings,
  Ticket,
  Transaction,
  Transactions,
} from "./screens";
import ResetSuccess from "./screens/auth/ResetSuccess";
import { AuthProvider } from "./services/auth-context";

const App: React.FC = () => (
  <Router>
    <AuthProvider>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/reset" component={SetPassword} />
        <Route path="/success" component={ResetSuccess} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/plus" component={Plus} />
        <PrivateRoute path="/transactions/:id" component={Transaction} />
        <PrivateRoute path="/transactions" component={Transactions} />
        <PrivateRoute path="/merchants/:merchantId/locations/:id" component={Location} />
        <PrivateRoute path="/merchants/:id" component={Merchant} />
        <PrivateRoute path="/merchants" component={Merchants} />
        <PrivateRoute path="/reseller/:id" component={Reseller} />
        <PrivateRoute path="/resellers" component={Resellers} />
        <PrivateRoute path="/locations/:id" component={Location} />
        <PrivateRoute path="/locations" component={Locations} />
        <PrivateRoute path="/devices" component={Devices} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/labels" component={Labels} />
        <PrivateRoute path="/profile" component={ProfileSettings} />
        <PrivateRoute path="/tickets/:id" component={Ticket} />
      </Switch>
    </AuthProvider>
  </Router>
);
export default App;

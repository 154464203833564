import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../components/Button";
import DataContainer from "../components/DataContainer";
import DataTable from "../components/DataTable";
import DetailsSection from "../components/DetailsSection";
import UsersList from "../components/lists/UsersList";
import MainHeader from "../components/MainHeader";
import LocationModal from "../components/modals/LocationModal";
import MerchantModal from "../components/modals/MerchantModal";
import NewUserModal from "../components/modals/NewUserModal";
import PlaceHolderDash from "../components/PlaceholderDash";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import * as Conduit from "../services/Conduit";
import Main from "./containers/Main";

type Props = {
  id?: string;
};

const Merchant: React.FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const { params } = props.match;
  const { overrideUser, isAdmin } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isCreatingLocation, setIsCreatingLocation] = useState(false);
  const [ShowingNewUserModal, setShowingNewUserModal] = useState(false);
  const [merchant, setMerchant] = useState<any[] | any>(null);
  const [locations, setLocations] = useState<any[] | any>(null);
  const [users, setUsers] = useState<any>(null);
  const history = useHistory();

  const getUsers = () => {
    if (!merchant) return;
    Conduit.usersAccount.getUsers(merchant.id, {
      "copper-account": merchant.id,
    })
      .then((usr) => {
        setUsers(usr.data);
      });
  };

  const deleteUser = (userId: string) => {
    if (!merchant) return;
    Conduit.usersAccount.deleteUser(merchant.id, userId)
      .then(() => {
        getUsers();
      });
  };
  const reInviteUser = (userId: string) => {
    if (!merchant) return;
    Conduit.usersAccount.reInviteUser(merchant.id, userId, true);
  };

  const getLocations = () =>
    locations.map((location: any) => ({
      click: () => {
        history.push(`/merchants/${merchant.id}/locations/${location.id}`);
      },
      display_name: location.display_name,
      devices: <PlaceHolderDash />,
      address: `${location.address.city}, ${location.address.state}`,
    }));

  const updateLocations = (merchant: any) => {
    API.get("Conduit", `/locations`, {
      headers: { "copper-account": merchant?.id },
      queryStringParameters: { limit: 100 },
    }).then((locations) => setLocations(locations.data));
  };

  useEffect(() => {
    API.get("Conduit", `/accounts/${params.id}`, {
      headers: { "copper-account": params.id },
    }).then((merchant) => {
      setMerchant(merchant);
      updateLocations(merchant);
    });
  }, [params]);

  useEffect(() => {
    getUsers();
  }, [merchant]);

  if (!merchant) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const headerSection = (
    <div className="border rounded-md p-responsive">
      <div className="flex flex-col justify-between mb-6 md:flex-row">
        <div className="w-full flex justify-content">
          <ResourceHeader>Merchant</ResourceHeader>
          {isAdmin && (
            <button
              className="mx-5 text-gray-400 font-medium text-12px  underline"
              onClick={() => overrideUser(merchant.id)}
            >
              Merchant View
            </button>
          )}
        </div>
        <ResourceId>{merchant.id}</ResourceId>
      </div>
      <div className="flex flex-row justify-between">
        <MainHeader>{merchant.name}</MainHeader>
      </div>
    </div>
  );

  const detailsSection = (
    <DetailsSection
      nameDescription="Merchant Name"
      name={merchant.name}
      address={merchant.address}
      onEditClicked={() => setIsEditing(true)}
    />
  );

  const locationsSection = (
    <div>
      <DataContainer
        title="Locations"
        controls={
          <Button
            onClick={() => {
              setIsCreatingLocation(true);
            }}
            icon="ion-android-add"
          >
            New
          </Button>
        }
        removeInnerPadding={true}
      >
        {locations
          ? (
            <DataTable
              noItemsHeader="No locations registered"
              noItemsBody="Locations must be registered before being visible here."
              data={getLocations()}
              tdChildClass="px-8 w-full"
              trChildClass="copper-data-row-height-merchant"
              noHeaders={true}
              roundCorners={true}
              columns={[
                {
                  name: "display_name",
                  label: "",
                  type: "string",
                },
                {
                  name: "devices",
                  label: "",
                  type: "string",
                },
                {
                  name: "address",
                  label: "",
                  type: "string",
                  tdClass: "w-1/3 text-left",
                },
              ]}
            />
          )
          : (
            <div className="flex justify-center p-responsive">
              <Spinner size={20} color="gray"></Spinner>
            </div>
          )}
      </DataContainer>
    </div>
  );

  const onModalCancel = () => {
    setIsEditing(false);
    setIsCreatingLocation(false);
    setShowingNewUserModal(false);
  };
  const onEditMerchantSubmit = () => {
    setIsEditing(false);
    API.get("Conduit", `/accounts/${params.id}`, { type: "merchant" }).then((merchant) => {
      setMerchant(merchant);
    });
  };
  const editMerchantModal = (
    <MerchantModal
      merchant={merchant}
      visible={isEditing}
      onSubmitSuccess={onEditMerchantSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={!isEditing}
    />
  );

  const onNewLocationSubmit = () => {
    setIsCreatingLocation(false);
    updateLocations(merchant);
  };

  const newLocationModal = (
    <LocationModal
      visible={isCreatingLocation}
      onSubmitSuccess={onNewLocationSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={true}
      merchantId={merchant?.id}
      devices={[]}
      preselectedCountry={merchant?.address?.country}
    />
  );

  const userSection = (
    <div>
      <DataContainer
        title="Users"
        controls={
          <Button
            onClick={() => {
              setShowingNewUserModal(true);
            }}
            icon="ion-android-add"
          >
            New
          </Button>
        }
        removeInnerPadding={true}
      >
        <UsersList
          account={merchant}
          users={users}
          onDeleteUser={deleteUser}
          onReInviteUser={reInviteUser}
        />
        <NewUserModal
          merchantId={merchant.id}
          visible={ShowingNewUserModal}
          onSubmitSuccess={() => {
            getUsers();
            setShowingNewUserModal(false);
          }}
          onCancel={() => onModalCancel()}
          onClickOutside={() => onModalCancel()}
        />
      </DataContainer>
    </div>
  );

  return (
    <Main>
      <div className="space-y-4 md:space-y-8">
        {[
          headerSection,
          detailsSection,
          locationsSection,
          userSection,
        ]}
      </div>
      {editMerchantModal}
      {newLocationModal}
    </Main>
  );
};

export default Merchant;

import { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { stringify as uuidStringify } from "uuid";
import AuthBackdrop from "../../components/auth/AuthBackdrop";
import { useAuth } from "../../services/auth-context";
import { ForgotPassword } from "..";

const useQuery = () => new URLSearchParams(useLocation().search);

const SetPassword: React.FC = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const { isLoggedIn, initPassword } = useAuth();
  const token = useQuery().get("t");
  const isNewPassword = useQuery().get("action");
  const history = useHistory();
  const password = useRef({});
  const [error, setError] = useState("");

  if ((isNewPassword !== "newpassword") && !token) {
    return <ForgotPassword />;
  }

  password.current = watch("password", "");

  const ResetPasswordFlow = (formData: any) => {
    const username = token ? uuidStringify(Buffer.from(token.slice(0, 32), "hex")) : "";
    const code = token ? token.slice(32, 38).toString() : "";
    const { password } = formData;

    try {
      setError("");
      Auth.forgotPasswordSubmit(username, code, password)
        .then((res) => {
          history.replace("/success");
        })
        .catch((err) => {
          setError(err.message);
        });
    } catch (error) {}
  };

  const SetInitialPassword = async (formData: any) => {
    try {
      await initPassword(formData.password);
      history.push({ pathname: "/" });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const setPassword = (formData: any) => {
    if (isNewPassword === "newpassword") {
      SetInitialPassword(formData);
    } else if (token) {
      ResetPasswordFlow(formData);
    }
  };

  if (isLoggedIn === true) return <Redirect to={{ pathname: "/" }} />;

  return (
    <AuthBackdrop>
      <>
        <div className="mt-6"></div>
        <h2 className="auth-title">{"Reset your password"}</h2>
        <div className="mt-6"></div>
        <form className="space-y-7" onSubmit={handleSubmit(setPassword)}>
          <div>
            <label htmlFor="password" className="auth-label">
              New password
            </label>
            <div className="mt-1">
              <input
                ref={register({
                  required: "You must specify a password",
                })}
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="copper-input"
              />
              {errors.password && <p>{errors.password.message}</p>}
            </div>
          </div>

          <div>
            <label htmlFor="password" className="auth-label">
              {"Confirm your password"}
            </label>
            <div className="mt-1">
              <input
                ref={register({
                  validate: (value) => value === password.current || "The passwords do not match",
                })}
                id="confirm-password"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                required
                className="copper-input"
              />
              {errors.newPassword && <p>{errors.newPassword.message}</p>}
            </div>
          </div>

          {!!error && <p className="label-error">{error}</p>}

          <div>
            <button type="submit" className="copper-button-confirm-full">
              {"Continue"}
            </button>
          </div>
        </form>

        <div className="mt-6"></div>
      </>
    </AuthBackdrop>
  );
};

export default SetPassword;

import React, { useState } from "react";
import Toggle from "../../Toggle";

const QRSettingsComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
}> = ({ location, setValue, register, isHide, isDisabled = false }) => {
  const { settings } = location;
  const { qr_payments } = settings;
  const [toggleQrEnabled, setToggleQrEnabled] = useState(qr_payments?.enabled);
  const [gateway, setGateway] = useState(qr_payments?.gateway);
  const [redirectURL, setRedirectURL] = useState(qr_payments?.redirect_url ?? "");

  return (isHide !== true)
    ? (
      <div>
        <div className="flex items-center">
          <Toggle
            onChange={() => {
              const newValue = !toggleQrEnabled;
              setToggleQrEnabled(newValue);
              setValue("qr_payments_enabled", newValue);
            }}
            value={toggleQrEnabled}
          />
          <span className="text-sm fsont-medium pl-4 text-gray-900">
            Enable QR code on tickets
          </span>
          <input
            id="qr_payments-enabled"
            name="qr_payments_enabled"
            value={toggleQrEnabled}
            ref={register()}
            type={"hidden"}
          />
        </div>
        <div className={!toggleQrEnabled ? "hidden" : "flex items-center pt-2"}>
          <input
            ref={register}
            type="radio"
            id="qr_method-checkout"
            name="qr_method"
            value="checkout"
            defaultChecked={!qr_payments?.redirect_url}
            onClick={(e) => {
              // setRedirectURL("");
              // setValue("redirect_url", "");
              setValue("qr_method", "checkout");
            }}
          />
          <span className="text-sm fsont-medium pl-4 text-gray-900 w-80">
            Copper payments via
          </span>
          <select
            ref={register}
            id="qr_payments-gateway"
            name="qr_payments_gateway"
            className="copper-input-dropdown"
            value={gateway}
            onChange={(e) => {
              setGateway(e.target.value);
              setValue("qr_payments_gateway", e.target.value);
            }}
          >
            <option value="">{"[Select a gateway]"}</option>
            <option value="bluefin">Bluefin PayConex</option>
            <option value="stripe">Stripe Connect</option>
            <option value="transit">TSYS TransIT</option>
            <option value="express">Worldpay Express</option>
          </select>
        </div>
        <div className={!toggleQrEnabled ? "hidden" : "flex items-center pt-1"}>
          <input
            ref={register}
            type="radio"
            id="qr_method-redirect"
            name="qr_method"
            value="redirect"
            defaultChecked={qr_payments?.redirect_url}
            onClick={(e) => {
              // setRedirectURL(qr_payments?.redirect_url ?? "");
              // setValue("redirect_url", redirectURL);
              setValue("qr_method", "redirect");
            }}
          />
          <span className="text-sm fsont-medium pl-4 text-gray-900 w-32">
            Custom URL
          </span>
          <input
            ref={register()}
            id="redirect-url"
            name="redirect_url"
            className="copper-input"
            value={redirectURL}
            onChange={(e) => {
              setRedirectURL(e.target.value);
              setValue("redirect_url", e.target.value);
            }}
          />
        </div>
      </div>
    )
    : null;
};

export default React.memo(QRSettingsComponent);

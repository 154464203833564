import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import ResellersList from "../components/lists/ResellersList";
import MainHeader from "../components/MainHeader";
import ResellerModal from "../components/modals/ResellerModal";
import PaginationControls from "../components/PaginationControls";
import Main from "./containers/Main";

const Resellers: React.FC = () => {
  const history = useHistory();
  const [merchants, setMerchants] = useState<any[] | any>(null);
  const [showingNewModal, setShowingNewModal] = useState(false);

  const onModalSubmitSuccess = (newMerchant: any) => {
    setShowingNewModal(false);
    history.push(`/reseller/${newMerchant.id}`);
  };

  const onModalCancel = () => {
    setShowingNewModal(false);
  };

  return (
    <Main>
      <div className="p-4 flex justify-between pb-8">
        <MainHeader>Resellers</MainHeader>
        <Button onClick={() => setShowingNewModal(true)} icon="ion-android-add">
          New
        </Button>
      </div>
      <ResellersList merchants={merchants} history={history} />
      <PaginationControls
        endpoint="/accounts"
        onListChange={setMerchants}
        requestParameters={{ type: "reseller" }}
      />
      <ResellerModal
        visible={showingNewModal}
        onCancel={onModalCancel}
        onSubmitSuccess={onModalSubmitSuccess}
        onClickOutside={onModalCancel}
        removeBlankFormValues={true}
        sendType={true}
      />
    </Main>
  );
};

export default Resellers;

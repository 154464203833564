import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import Spinner from "../Spinner";

const UsersList: React.FC<{
  account: any;
  users: any;
  onDeleteUser: any;
  onReInviteUser: any;
}> = ({ account, users, onDeleteUser, onReInviteUser }) => {
  const [locations, setLocations] = useState<any[] | any>([]);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      headers: { "copper-account": account.id },
      queryStringParameters: { limit: 100 },
    }).then((locations: any) => {
      if (locations.data) {
        setLocations(locations.data.map((l: any) => ({ id: l.id, name: l.display_name })));
      }
    });
  }, []);

  if (!users || !locations) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }

  const customControls = (id: string, status: string) => (
    <div className="text-right space-x-4	">
      {status === "FORCE_CHANGE_PASSWORD" && (
        <button
          className="copper-button-cancel p-2"
          onClick={() => onReInviteUser(id)}
        >
          Resend Invite
        </button>
      )}
      <button
        className="copper-button-delete text-white bg-red-600 p-2"
        onClick={() => onDeleteUser(id)}
      >
        Delete
      </button>
    </div>
  );

  const mappedUsers = users?.map(({ id, name, email, status, role, location }: any) => ({
    name,
    controls: customControls(id, status),
    email,
    role: capitalize(role) || "Owner",
    location: locations.find((l: any) => l.id === location)?.name,

    click: () => {},
  })) || [];

  const columns = [
    {
      name: "name",
      label: "Name",
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "email",
      label: "Email",
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "role",
      label: "Role",
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "location",
      label: "Location",
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "controls",
      label: "",
      type: "string",
      headerClass: "w-1/3",
    },
  ];

  return (
    <DataTable
      data={mappedUsers}
      noItemsHeader="No users created"
      noItemsBody="Users must be created before being visible here."
      columns={columns}
      headerClasses="px-8"
      tdChildClass="px-8 py-2"
      // tdClass='px-responsive-sm py-responsive-sm'
      disableFullWidthCells={true}
      disableFlexBetween={true}
      // tdChildClass="px-4 py-2"
      noHeaders={false}
      roundCorners={true}
    />
  );
};

export default UsersList;

function capitalize(str: string) {
  return str ? str[0].toUpperCase() + str.slice(1) : str;
}

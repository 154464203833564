import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { first, last } from "../util/arrayHelper";
import objectToQueryString from "../util/objectToQueryString";
import Button from "./Button";
import CenteredErrorLabel from "./CenteredErrorLabel";

const PaginationControls: React.FC<{
  endpoint: string;
  onListChange: any;
  initialPage?: number;
  ignoreUrlChange?: boolean;
  initialLimitOverride?: number;
  requestParameters?: any;
  onPreviousSideEffect?: any;
  onNextSideEffect?: any;
  setRefreshList?: any;
  refresh?: boolean;
  hide?: boolean;
}> = ({
  endpoint,
  onListChange,
  initialPage,
  ignoreUrlChange,
  initialLimitOverride,
  requestParameters,
  onPreviousSideEffect,
  onNextSideEffect,
  hide,
}) => {
  const [hasPreviouslyMadeRequests, setHasPreviouslyMadeRequests] = useState(false);
  const [awaitingRequest, setAwaitingRequest] = useState(false);
  const [page, setPage] = useState(initialPage || 1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [pageLimit] = useState(initialLimitOverride || 20);
  const [firstLastIds, setFirstLastIds] = useState({ first: null, last: null });
  const [lastRequestOptions, setLastRequestOptions] = useState<any>({});
  const [wentForward, setWentForward] = useState(true);
  const [wentBackward, setWentBackward] = useState(false);
  const [error, setError] = useState("");

  const makeRequest = (queryStringParameters: any, useLast?: boolean) => {
    setAwaitingRequest(true);

    const parameters = {
      ...queryStringParameters,
      ...requestParameters,
    };

    setPage(parameters?.page || 1);
    const parametersCopy = { ...parameters };
    delete parameters.page;
    delete parametersCopy.limit;

    const options = useLast
      ? lastRequestOptions
      : {
        queryStringParameters: parameters,
      };

    if (!ignoreUrlChange) {
      window.history.replaceState("", "", `?${objectToQueryString(parametersCopy)}`);
    }

    setLastRequestOptions(options);
    API.get("Conduit", endpoint, options)
      .then(({ data: recordsList, has_more }) => {
        setHasMorePages(has_more);
        if (recordsList) {
          setFirstLastIds({
            first: first(recordsList)?.id,
            last: last(recordsList)?.id,
          });
          onListChange(recordsList);
        }
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
        onListChange([]);
      })
      .finally(() => setAwaitingRequest(false));
  };

  useEffect(() => {
    if (Object.keys(requestParameters).length && !hasPreviouslyMadeRequests) {
      setHasPreviouslyMadeRequests(true);
      setLastRequestOptions(requestParameters);
      makeRequest({ limit: pageLimit, ...requestParameters });
    } else {
      makeRequest({ limit: pageLimit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrevious = () => {
    makeRequest({
      page: page - 1,
      limit: pageLimit,
      ending_before: firstLastIds.first,
    });
    setWentForward(false);
    setWentBackward(true);
    onPreviousSideEffect && onPreviousSideEffect();
  };

  const onNext = () => {
    makeRequest({
      page: page + 1,
      limit: pageLimit,
      starting_after: firstLastIds.last,
    });
    setWentForward(true);
    setWentBackward(false);
    onNextSideEffect && onNextSideEffect();
  };

  if (!!error) {
    return <CenteredErrorLabel message={error} />;
  }

  if (hide) {
    return <></>;
  }

  return (
    <nav
      className="bg-white px-4 py-3 mt-2 flex items-center justify-between border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="flex-1 flex justify-between space-x-2 sm:justify-end">
        <Button
          onClick={onPrevious}
          disabled={awaitingRequest || (!wentForward && wentBackward && !hasMorePages) || page === 1}
        >
          Previous
        </Button>
        <Button onClick={onNext} disabled={awaitingRequest || (wentForward && !wentBackward && !hasMorePages)}>
          Next
        </Button>
      </div>
    </nav>
  );
};

export default PaginationControls;

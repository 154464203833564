import React from "react";

const TitledDetailRows: React.FC<{
  className?: string;
  title?: React.ReactNode;
  details: Array<{
    name: string;
    value: any;
    isHide?: boolean;
  }>;
}> = ({ className, title, details }) => (
  <div className={className}>
    {title && <div className="text-3xl font-medium border-b pb-6 mb-6">{title}</div>}
    <div className="flex flex-col space-y-8">
      {details.map(({ name, value, isHide }, i) => !isHide && <DetailRow name={name} value={value} key={i} />)}
    </div>
  </div>
);

const DetailRow: React.FC<{
  name: string;
  value: any;
}> = ({ name, value }) => {
  return (
    <div className="flex flex-row">
      <DetailName name={name} /> <DetailValue value={value} />
    </div>
  );
};

const DetailName: React.FC<{
  name: string;
}> = ({ name }) => {
  return <span className="opacity-50 w-36">{name}</span>;
};

const DetailValue: React.FC<{
  value: string;
}> = ({ value }) => {
  return <span className="capitalize">{value}</span>;
};

export default TitledDetailRows;

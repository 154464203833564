import React from "react";
import { useAuth } from "../../services/auth-context";
import UserButton from "./UserButton";

const Header: React.FC = () => {
  const { resetUser, isOverrideUser, isReseller, user } = useAuth();

  const bg = isOverrideUser ? "bg-yellow-200" : "bg-white";

  return (
    user && (
      <div className={`${bg} flex items-center border-gray-light border-b border-opacity-40 z-10 shadow`}>
        {isOverrideUser && (
          <>
            <div className="pl-8 text-base">Viewing as {isReseller() ? "Reseller" : "Merchant"}</div>
            <div
              className="px-8 text-base underline cursor-pointer"
              onClick={() => resetUser()}
            >
              Switch back to admin view
            </div>
          </>
        )}
        <UserButton name={user.attributes.name} email={user.attributes.email} />
      </div>
    )
  );
};

export default Header;

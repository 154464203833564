import React from "react";

const LeftLabeledContainer: React.FC<{ name: string; widthClass?: string }> = ({ name, children, widthClass }) => {
  return (
    <div className="flex">
      <div className="flex flex-col space-y-6 pr-7">
        <label className={`text-responsive-base font-medium py-2 ${widthClass || "w-24"}`}>{name}</label>
      </div>
      <div className="flex flex-col space-y-6 w-full">{children}</div>
    </div>
  );
};

export default LeftLabeledContainer;

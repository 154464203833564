import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Button from "../components/Button";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import DataContainer from "../components/DataContainer";
import DetailsSection from "../components/DetailsSection";
import DeviceList from "../components/lists/DeviceList";
import MainHeader from "../components/MainHeader";
import BrandingLocationModal from "../components/modals/BrandingLocationModal";
import LocationModal from "../components/modals/LocationModal";
import NewDeviceModal from "../components/modals/NewDeviceModal";
import PaymentSettingsModal from "../components/modals/PaymentSettings";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import Main from "./containers/Main";

type Props = {
  id?: string;
  merchantId?: string;
};

const Location: React.FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const { params } = props.match;
  const { user } = useAuth();
  const accountId = user!.account;
  const locationId = params!.id!;

  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isCreatingDevice, setIsCreatingDevice] = useState(false);
  const [location, setLocation] = useState<any[] | any>(null);
  const [devices, setDevices] = useState<any[] | any>([]);
  const [showingPaymentSettings, setShowingPaymentSettings] = useState(false);
  const [showingBrandingLocation, setShowingBrandingLocation] = useState(false);
  const [devicesError] = useState("");

  const merchantId = params.merchantId || accountId;

  useEffect(() => {
    getLocation(locationId, merchantId, setLocation);
    getDevices(locationId, merchantId, setDevices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!location) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const onModalCancel = () => {
    setIsEditingLocation(false);
    setIsCreatingDevice(false);
    setShowingPaymentSettings(false);
    setShowingBrandingLocation(false);
  };
  const onEditLocationSubmit = () => {
    setIsEditingLocation(false);
    getLocation(locationId, merchantId, setLocation);
  };
  const editLocationModal = (
    <LocationModal
      location={location}
      visible={isEditingLocation}
      onSubmitSuccess={onEditLocationSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={!isEditingLocation}
      merchantId={merchantId}
      devices={devices}
      preselectedCountry={location?.address?.country}
    />
  );

  const updateDeviceList = () => {
    getDevices(locationId, merchantId, setDevices);
  };
  const onNewDeviceSubmit = () => {
    setIsCreatingDevice(false);
    updateDeviceList();
  };
  const newDeviceModal = (
    <NewDeviceModal
      locationId={location.id}
      visible={isCreatingDevice}
      onSubmitSuccess={onNewDeviceSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      merchantId={merchantId}
    />
  );

  const paymentSettingsModal = (
    <PaymentSettingsModal
      visible={showingPaymentSettings}
      location={location}
      merchantId={merchantId}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      updateLocation={() => getLocation(locationId, merchantId, setLocation)}
    >
    </PaymentSettingsModal>
  );

  const brandingLocationModal = (
    <BrandingLocationModal
      visible={showingBrandingLocation}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      onSubmitSuccess={() => setShowingBrandingLocation(false)}
      merchantId={merchantId}
      location={location}
      updateLocation={() => getLocation(locationId, merchantId, setLocation)}
    />
  );

  return (
    <Main>
      <div className="space-y-4 md:space-y-8">
        {buildHeader(location, setShowingPaymentSettings, setShowingBrandingLocation)}
        {buildDetails(location, setIsEditingLocation)}
        <div>
          <DataContainer
            title="Devices"
            titleClass="text-responsive-lg my-auto"
            controls={
              <Button
                onClick={() => setIsCreatingDevice(true)}
                icon="ion-android-add"
              >
                New
              </Button>
            }
            removeInnerPadding={true}
          >
            {!!devicesError ? <CenteredErrorLabel message={devicesError} /> : (
              <DeviceList
                noItemsHeader="No devices registered"
                noItemsBody="Devices must be registered to this location before being visible here."
                devices={devices}
                onDeviceEdited={updateDeviceList}
                onDeviceRemoved={updateDeviceList}
                merchantId={merchantId}
              />
            )}
          </DataContainer>
        </div>
      </div>
      {editLocationModal}
      {newDeviceModal}
      {paymentSettingsModal}
      {brandingLocationModal}
    </Main>
  );
};

const buildHeader = (location: any, setShowingPaymentSettings: any, setShowingBrandingLocation: any) => (
  <div className="border rounded-md p-responsive">
    <div className="flex justify-between mb-6 flex-row">
      <ResourceHeader>Location</ResourceHeader>
      <ResourceId>{location.id}</ResourceId>
    </div>
    <div className="flex flex-row justify-between">
      <MainHeader>{location.display_name}</MainHeader>
      <div className="flex flex-row justify-between gap-4">
        <Button onClick={() => setShowingBrandingLocation(true)}>
          Branding
        </Button>
        <Button onClick={() => setShowingPaymentSettings(true)} icon="ion-gear-a">
          Payment Settings
        </Button>
      </div>
    </div>
  </div>
);

const buildDetails = (location: any, setIsEditingLocation: any) => (
  <DetailsSection
    nameDescription="Location Name"
    name={location.display_name}
    address={location.address}
    onEditClicked={() => setIsEditingLocation(true)}
  />
);

const getLocation = (locationId: string, merchantId: string, setLocation: Function) =>
  API.get("Conduit", `/locations/${locationId}`, {
    headers: { "copper-account": merchantId },
  })
    .then((location) => setLocation(location));

const getDevices = (locationId: string, merchantId: string, setDevices: Function) =>
  API.get("Conduit", `/devices?location=${locationId}&include[]=wifi_rssi`, {
    headers: { "copper-account": merchantId },
    queryStringParameters: { limit: 100 },
  }).then((devices) => setDevices(devices.data));

export default Location;

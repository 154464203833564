import React from "react";
import AddressInfo from "./AddressInfo";
import Button from "./Button";
import DataContainer from "./DataContainer";

const DetailsSection: React.FC<{ nameDescription: string; name: string; address: any; onEditClicked?: Function }> = ({
  nameDescription,
  name,
  address,
  onEditClicked,
}) => {
  return (
    <div>
      <DataContainer
        title="Details"
        controls={onEditClicked && (
          <Button onClick={onEditClicked} icon={"ion-edit"} iconSize={18}>
            Edit
          </Button>
        )}
      >
        <div className="flex flex-col md:flex-row pb-responsive">
          <div className="w-0 text-sm md:text-base whitespace-nowrap text-gray-400">{nameDescription}</div>
          <div className="px-2 md:pl-56">{name}</div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="w-0 text-sm md:text-base  whitespace-nowrap text-gray-400">Address</div>
          <div className="px-2 md:pl-56">
            <AddressInfo address={address} />
          </div>
        </div>
      </DataContainer>
    </div>
  );
};

export default DetailsSection;

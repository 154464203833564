import React from "react";
import SubHeader from "./SubHeader";

const DataContainer: React.FC<{
  children: string | Array<JSX.Element> | JSX.Element;
  title: string;
  titleClass?: string;
  controls?: any;
  removeInnerPadding?: boolean;
}> = ({ title, titleClass, children, controls, removeInnerPadding }) => (
  <>
    <div className="flex justify-between border-t border-l border-r rounded-t-md p-responsive h-24">
      {titleClass ? <div className={titleClass}>{title}</div> : <SubHeader>{title}</SubHeader>}
      <div className="-mt-1">{controls}</div>
    </div>
    <div className={`border rounded-b-md ${!removeInnerPadding && "p-responsive"}`}>{children}</div>
  </>
);

export default DataContainer;

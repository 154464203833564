import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const BuildTsysOptions = (props: any) => {
  const {
    location,
    updateLocation,
    merchantId,
    register,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    requestError,
    setRequestError,
  } = props;
  const apiTransitEnpoint = "/transit/keys";
  const apiLocationEndpoint = `/locations/${location.id}`;
  const isConnected = !(location.settings?.transit_payments?.merchant_id === null
    || location.settings?.transit_payments?.tsep_device_id === null);

  const disconnectFromTransit = () => {
    setIsSubmitting(true);
    API.post("Conduit", apiLocationEndpoint, {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          transit_payments: "",
        },
      },
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromTransit}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Connect</span>
      </>
    </button>
  );

  const getTransitKey = (credentials: any) => {
    const { user_id, password, merchant_id } = credentials;
    return API.post("Conduit", apiTransitEnpoint, {
      headers: { "copper-account": merchantId },
      body: {
        merchant_id,
        user_id,
        password,
      },
    })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      });
  };

  const onSave = (formData: any) => {
    console.log("formData", formData);
    setIsSubmitting(true);
    getTransitKey(formData).then((data) => {
      const {
        tsep_device_id,
        cnp_multipass_device_id,
        merchant_id,
      } = formData;

      if (data.key === null) {
        setRequestError("You account cannot be set up. Please contact your payment solution provider.");
        setIsSubmitting(false);
        return;
      } else {
        API.post("Conduit", apiLocationEndpoint, {
          headers: { "copper-account": merchantId },
          body: {
            settings: {
              transit_payments: {
                merchant_id,
                tsep_device_id,
                cnp_multipass_device_id,
                transaction_key: data.key || "6KJ8J9HJ9H988GI9F99HJ9K9F99D8",
              },
            },
          },
        })
          .then(() => {
            updateLocation();
          })
          .catch((err) => {
            setRequestError(err.response?.data?.error?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    });
  };

  return (
    <form className="h-full" onSubmit={handleSubmit(onSave)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-1">
          <div className="m-0">
            <label htmlFor="merchant-id" className="block text-base py-1 text-gray-700">Merchant ID</label>
            <input
              ref={register()}
              id="merchant-id"
              name="merchant_id"
              autoComplete="off"
              disabled={isConnected}
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.transit_payments?.merchant_id}
            />
          </div>
          {isConnected && (
            <div className="m-0">
              <label htmlFor="transaction-key" className="block text-base py-1 text-gray-700">Transaction Key</label>
              <input
                id="transaction-key"
                name="transaction_key"
                autoComplete="off"
                type="password"
                disabled={isConnected}
                className={"copper-input-disabled"}
                defaultValue="******************************"
              />
            </div>
          )}

          {!isConnected && (
            <div className="flex justify-between">
              <div className="">
                <label htmlFor="user-id" className=" text-base py-1 text-gray-700">
                  User Id
                </label>
                <input
                  ref={register()}
                  id="user-id"
                  name="user_id"
                  className="copper-input"
                  autoComplete="off"
                  defaultValue={""}
                />
              </div>
              <div className="w-52">
                <span
                  className="has-tooltip float-right"
                  onMouseOver={() => {}}
                >
                  <span className="tooltip rounded shadow-lg  bg-white p-3 w-56 text-base mt-0 -ml-52">
                    Your User ID and Password is used to obtain a secure Transaction Key, and they will not be saved.
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 fill-current text-gray-400 "
                    viewBox="0 0 512 512"
                  >
                    <title>Help Circle</title>
                    <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64zm-6 304a20 20 0 1120-20 20 20 0 01-20 20zm33.44-102C267.23 276.88 265 286.85 265 296a14 14 0 01-28 0c0-21.91 10.08-39.33 30.82-53.26C287.1 229.8 298 221.6 298 203.57c0-12.26-7-21.57-21.49-28.46-3.41-1.62-11-3.2-20.34-3.09-11.72.15-20.82 2.95-27.83 8.59C215.12 191.25 214 202.83 214 203a14 14 0 11-28-1.35c.11-2.43 1.8-24.32 24.77-42.8 11.91-9.58 27.06-14.56 45-14.78 12.7-.15 24.63 2 32.72 5.82C312.7 161.34 326 180.43 326 203.57c0 33.83-22.61 49.02-42.56 62.43z" />
                  </svg>
                </span>
                <label htmlFor="password" className=" text-base py-1 text-gray-700">
                  Password
                </label>
                <input
                  ref={register()}
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="off"
                  className="copper-input"
                  defaultValue={""}
                />
              </div>
            </div>
          )}
          <div className="m-0">
            <label htmlFor="merchant-id" className="block text-base py-1 text-gray-700">TSEP Device ID</label>
            <input
              ref={register()}
              id="tsep-device-id"
              name="tsep_device_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.transit_payments?.tsep_device_id}
            />
          </div>
          <div className="m-0">
            <label htmlFor="merchant-id" className="block text-base py-1 text-gray-700">CNP MultiPASS Device ID</label>
            <input
              ref={register()}
              id="cnp-multipass-device-id"
              name="cnp_multipass_device_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.transit_payments?.cnp_multipass_device_id}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between">
          {!!requestError ? <span className="label-error my-auto">{requestError}</span> : <div />}
          {isConnected ? disconnectButton : connectButton}
        </div>
      </div>
    </form>
  );
};

export const TsysTab = {
  name: "Tsys",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className={!active ? "image-grayscale" : ""}
        width="139"
        alt="t-sys-logo"
        src="/img/payment_options/tsys-active.png"
      />
    </>
  ),
  headerText: "Connect with TransIT",
  build: BuildTsysOptions,
};

export default React.memo(BuildTsysOptions);

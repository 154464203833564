import React, {useEffect, useState} from "react";
import countrystate from "../json/countrystate.json";
import FormInput from "./FormInput";
import FormInputError from "./FormInputError";
import {getCurrency, getZipCodeRegex, locale} from "../util/Locale";
import {useAuth} from "../services/auth-context";
import LeftLabeledContainer from "./LeftLabeledContainer";

const AddressFields: React.FC<{
    label?: any;
    containerWidthClass?: any;
    register: any;
    errors: any;
    defaultAddress: any;
    hook?: any;
    setHook?: any;
    preselectedCountry?: any;
}> = ({
          label,
          containerWidthClass,
          register,
          errors,
          defaultAddress,
          hook,
          setHook,
          preselectedCountry
      }) => {
    const {user} = useAuth();

    const [selectedCountry, setSelectedCountry] = useState(preselectedCountry ?? defaultAddress?.country ?? user?.accountData?.defaultAddress?.country ?? "US");
    const [stateOptions, setStateOptions] = useState([{name: "", abbreviation: ""}]);

    const [selectedState, setSelectedState] = useState(defaultAddress?.state || "");
    const zipFormat = getZipCodeRegex(selectedCountry);

    useEffect(() => {
        if (!selectedCountry) {
            return;
        }
        const selectedCountryData = countrystate.countries.find(
            (country: any) => country.country.abbreviation === selectedCountry,
        );
        if (selectedCountryData) {
            setStateOptions(selectedCountryData.states);
        }
    }, [selectedCountry]);

    const updateHook = (e: any, name: string) => {
        if (!setHook) {
            return;
        }
        setHook({
            ...hook,
            [name]: e.target.value,
        });
    };

    return (
        <>
            <LeftLabeledContainer name={label ?? "Address"} widthClass={containerWidthClass ?? "w-24"}>

                <div>
                    <select
                        ref={register({required: "Country required"})}
                        id="address[country]"
                        name="address[country]"
                        className="copper-input"
                        value={selectedCountry}
                        onChange={(e) => {
                            updateHook(e, "country");
                            const selectedCountry = e.target.value;
                            setSelectedCountry(selectedCountry);
                            const selectedCountryData = countrystate.countries.find(
                                (country: any) => country.country.abbreviation === selectedCountry,
                            );
                            if (selectedCountryData) {
                                setStateOptions(selectedCountryData.states);
                            }
                        }}
                    >
                        {countrystate.countries.map(({country}, i) => (
                            <option value={country.abbreviation}
                                    key={i}
                                    disabled={preselectedCountry != null && country.abbreviation != preselectedCountry}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                    <FormInputError error={errors?.address?.country?.message}/>
                </div>

                <FormInput
                    formRegisterRef={register({required: "Address required"})}
                    id="address[line1]"
                    placeholder="Business Address Line 1"
                    defaultValue={defaultAddress?.line1 ?? ""}
                    error={errors?.address?.line1}
                    onChange={(e: any) => updateHook(e, "line1")}
                />
                <FormInput
                    formRegisterRef={register()}
                    id="address[line2]"
                    placeholder="Business Address Line 2"
                    defaultValue={defaultAddress?.line2 ?? ""}
                    onChange={(e: any) => updateHook(e, "line2")}
                />
                <FormInput
                    formRegisterRef={register({required: "City required"})}
                    id="address[city]"
                    placeholder="City"
                    defaultValue={defaultAddress?.city ?? ""}
                    error={errors?.address?.city}
                    onChange={(e: any) => updateHook(e, "city")}
                />
                <div>
                    <select
                        ref={register({required: "State required"})}
                        id="address[state]"
                        name="address[state]"
                        className="copper-input"
                        value={selectedState}
                        onChange={(e) => {
                            updateHook(e, "state");
                            setSelectedState(e.target.value);
                        }}
                        disabled={stateOptions.length < 2}
                    >
                        <option value="" disabled>
                            Province/State
                        </option>
                        {stateOptions.map((state, i) => (
                            <option value={state.abbreviation} key={i}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                    <FormInputError error={errors?.address?.state?.message}/>
                </div>
                <FormInput
                    formRegisterRef={register({
                        pattern: {
                            value: zipFormat,
                            message: "Incorrect Zip code format"
                        }, required: "Zip required"
                    })}
                    id="address[postal_code]"
                    placeholder="Postal/Zip code"
                    defaultValue={defaultAddress?.postal_code ?? ""}
                    error={errors?.address?.postal_code}
                    onChange={(e: any) => updateHook(e, "postal_code")}
                />
            </LeftLabeledContainer>
            <LeftLabeledContainer name="Currency" widthClass={containerWidthClass ?? "w-24"}>
                <input
                    className="copper-input"
                    disabled={true}
                    placeholder="Currency"
                    value={getCurrency(selectedCountry)}>
                </input>
            </LeftLabeledContainer>
        </>);
};

export default AddressFields;

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormInputError from "../../FormInputError";
import useClickOutside from "../../utils/useClickOutside";
import InBetweenSettings from "./dateRanges/InBetweenSettings";
import InTheLastSettings from "./dateRanges/InTheLastSettings";
import IsEqualToSettings from "./dateRanges/IsEqualToSettings";

export const dateRangeOption: any = {
  inTheLast: "In the last",
  isEqualTo: "Is equal to",
  inBetween: "In between",
  isAfter: "Is after",
  isOnOrAfter: "Is on or after",
  isBefore: "Is before",
  isBeforeOrOn: "Is before or on",
};

export const defaultFilterSettings = {
  location: {
    enabled: false,
    id: "",
  },
  dateRange: {
    enabled: false,
    type: dateRangeOption.inTheLast,
    startDate: new Date(),
    endDate: new Date(),
    amount: 6,
    period: "days",
  },
  cardNumber: {
    enabled: false,
    number: "",
  },
  onlyPreAuth: {
    enabled: false,
  }
};

const TransactionFilter: React.FC<any> = (props) => {
  const { isShowingFilters, setIsShowingFilters, locations, filterSettings, setFilterSettings, setFiltering } = props;

  const modalRef = useClickOutside(() => {
    if (!isShowingFilters) {
      return;
    }
    setTimeout(() => setIsShowingFilters(false), 150);
  });

  if (!isShowingFilters || !locations) {
    return <></>;
  }

  const header = (
    <div className="rounded-t flex justify-between border-b bg-gray-50  p-1.5">
      <div className="text-responsive-base font-medium my-auto">Filters</div>
      <div className="text-responsive-xs font-light flex">
        <div
          className="cursor-pointer text-copper-purple p-0.5  mr-1 my-auto"
          onClick={() => {
            setFiltering(true);
            setFilterSettings(defaultFilterSettings);
          }}
        >
          Clear
        </div>
        <div
          className="cursor-pointer px-2 py-0.5 rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
          onClick={() => setFiltering(true)}
        >
          Apply
        </div>
      </div>
    </div>
  );

  const locationCheckbox = (
    <div className="flex p-2">
      <input
        id="enable-filter-location"
        type="checkbox"
        className="bg-copper-purple my-auto mr-1"
        checked={filterSettings.location.enabled}
        onChange={() =>
          setFilterSettings({
            ...filterSettings,
            location: {
              ...filterSettings.location,
              enabled: !filterSettings.location.enabled,
            },
          })}
      />
      <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
        <label htmlFor="enable-filter-location">Location</label>
      </div>
    </div>
  );

  const locationDropdown = (
    <div className="bg-gray-50 text-responsive-xs border-t border-b px-2 py-2.5">
      <select
        className={`${
          !filterSettings.location.id ? "text-gray-300" : "text-gray-500"
        } bg-white copper-input-basic rounded p-0.5 px-1.5 w-full`}
        value={filterSettings.location.id}
        onChange={(e) =>
          setFilterSettings({
            ...filterSettings,
            location: {
              ...filterSettings.location,
              id: e.target.value,
            },
          })}
      >
        <option disabled={true} value="">
          Select a location...
        </option>
        {locations.map((location: any) => <option value={location.id}>{location.name}</option>)}
      </select>
    </div>
  );

  const dateRangeCheckbox = (
    <div className="flex p-2">
      <input
        id="enable-filter-date-range"
        type="checkbox"
        className="my-auto mr-1"
        checked={filterSettings.dateRange.enabled}
        onChange={() =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              enabled: !filterSettings.dateRange.enabled,
            },
          })}
      />
      <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
        <label htmlFor="enable-filter-date-range">Date Range</label>
      </div>
    </div>
  );

  const cardNumberCheckbox = (
    <div className="flex p-2">
      <input
        id="enable-filter-card-number"
        type="checkbox"
        className="my-auto mr-1"
        checked={filterSettings.cardNumber.enabled}
        onChange={() =>
          setFilterSettings({
            ...filterSettings,
            cardNumber: {
              ...filterSettings.cardNumber,
              enabled: !filterSettings.cardNumber.enabled,
            },
          })}
      />
      <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
        <label htmlFor="enable-filter-card-number">Last 4 of CC</label>
      </div>
    </div>
  );

  const cardNumberInput = (
    <div className="bg-gray-50 rounded-b text-responsive-xs border-t px-2 py-2.5">
      <div className="h-0">
        <img className="relative left-1.5" width="15" alt="basic cc" src={`/img/cards/basic_cc.svg`} />
      </div>
      <input
        className="copper-input-basic rounded mr-2 pl-6 pr-1 w-14"
        maxLength={4}
        placeholder="0000"
        value={filterSettings.cardNumber.number}
        onChange={(e) =>
          setFilterSettings({
            ...filterSettings,
            cardNumber: {
              ...filterSettings.cardNumber,
              number: e.target.value,
            },
          })}
      />
    </div>
  );

  const onlyPreAuthCheckbox = (
    <div className="flex p-2">
      <input
        id="enable-filter-only-preauth"
        type="checkbox"
        className="my-auto mr-1"
        checked={filterSettings.onlyPreAuth.enabled}
        onChange={() =>
          setFilterSettings({
            ...filterSettings,
            onlyPreAuth: {
              ...filterSettings.onlyPreAuth,
              enabled: !filterSettings.onlyPreAuth.enabled,
            },
          })}
      />
      <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
        <label htmlFor="enable-filter-only-preauth">Only pre-authorized transactions</label>
      </div>
    </div>
  );

  return (
    <div
      ref={modalRef}
      className="shadow-lg absolute flex flex-col bg-white right-9 mt-1 top-36 w-56 border rounded-md z-50"
    >
      {header}
      {locationCheckbox}
      {locationDropdown}
      {dateRangeCheckbox}
      <DateRangeSection {...props} />
      {cardNumberCheckbox}
      {cardNumberInput}
      {onlyPreAuthCheckbox}
    </div>
  );
};

export const DateRangeSection: React.FC<any> = (props) => {
  const { filterSettings, setFilterSettings } = props;
  return (
    <div className="bg-gray-50 text-responsive-xs border-t border-b px-2 py-2.5">
      <select
        className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-full"
        value={filterSettings.dateRange.type}
        onChange={(e) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              type: e.target.value,
            },
          })}
      >
        {Object.keys(dateRangeOption).map((option, i) => (
          <option className="font-light" value={dateRangeOption[option]}>
            {dateRangeOption[option]}
          </option>
        ))}
      </select>

      <div className="flex justify-center mt-2">
        <DateRangeTypeSettings {...props} />
      </div>
    </div>
  );
};

const DateRangeTypeSettings: React.FC<any> = (props) => {
  const { filterSettings } = props;
  const { type } = filterSettings.dateRange;
  switch (type) {
    case dateRangeOption.inTheLast:
      return <InTheLastSettings {...props} />;

    case dateRangeOption.isEqualTo:
    case dateRangeOption.isAfter:
    case dateRangeOption.isOnOrAfter:
    case dateRangeOption.isBefore:
    case dateRangeOption.isBeforeOrOn:
      return <IsEqualToSettings {...props} />;

    case dateRangeOption.inBetween:
      return <InBetweenSettings {...props} />;

    default:
      return <FormInputError error={`Could not find settings for date range type: ${type}`} />;
  }
};

export default TransactionFilter;

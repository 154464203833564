import { API } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../services/auth-context";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import LeftLabeledContainer from "../LeftLabeledContainer";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import NameAddressForm from "../NameAddressForm";

const TIMES = Array(24).fill(0).map((e, i) => (i + ":00").padStart(5, "0"));

const LocationModal: React.FC<{
  location?: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  removeBlankFormValues: boolean;
  merchantId?: string;
  devices: any[];
  preselectedCountry?: any;
}> = ({location, onCancel, onSubmitSuccess, onClickOutside, visible, removeBlankFormValues, merchantId, devices,preselectedCountry}) => {
  const { register, handleSubmit, errors } = useForm();
  const { user, isReseller } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    const body = !removeBlankFormValues ? formData : removeEmptyObjectProps(formData);
    const isEdit = !!location;
    const base = "/locations";
    const endpoint = `${base}${isEdit ? `/${location.id}` : ""}`;
    const headers = merchantId ? { "copper-account": merchantId } : {};
    API.post("Conduit", endpoint, {
      headers,
      body,
    })
      .then((res) => {
        onSubmitSuccess();
      })
      .catch((err) => {
        setRequestError(err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={`${!!location ? "Edit" : "New"} location`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
      >
        <NameAddressForm
          register={register}
          errors={errors}
          display_name={location?.display_name}
          address={location?.address}
          usesDisplayName={true}
          preselectedCountry={preselectedCountry}
        />

        <LeftLabeledContainer name="Cut-off time">
          <div>
            <select
              ref={register({ required: "State required" })}
              id="cutoff_time"
              name="settings[cutoff_time]"
              className="copper-input float-left w-28"
              disabled={isReseller()}
              defaultValue={location?.settings.cutoff_time ? location?.settings.cutoff_time : "05:00"}
            >
              <option value="" disabled>
                Cut-off Time
              </option>
              {TIMES.map((time, i) => (
                <option value={time} key={i}>
                  {time}
                </option>
              ))}
            </select>
            <span className="has-tooltip float-left  ml-2">
              <span className="tooltip rounded shadow-lg z-[2147483647] bg-white p-2 border border-gray-100 text-base mt-6  ">
                The cut-off time is used to set when Cashier resets for the next day. Please set time on the hour with
                using the 24 hour clock .
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ionicon w-5 text-gray-light"
                fill="currentColor"
                viewBox="0 0 512 512"
              >
                <path d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200 200-89.72 200-200S366.28 56 256 56zm0 82a26 26 0 11-26 26 26 26 0 0126-26zm64 226H200v-32h44v-88h-32v-32h64v120h44z" />
              </svg>
            </span>
          </div>
        </LeftLabeledContainer>

        <LeftLabeledContainer name="Printer for payment confirmations">
          <div>
            <select
                ref={register()}
                id="preferred_printer"
                name="settings[preferred_printer]"
                className="copper-input"
                defaultValue={location?.settings?.preferred_printer}>
              <option value="">
                Same as the one to which the check is printed
              </option>
              {devices
                  .filter((device) => device.device_type === "copper_Cord")
                  .map((device) => (
                      <option value={device.serial_number} key={device.id}>
                        {device.label || device.serial_number}
                      </option>
                  ))
              }
            </select>
          </div>
        </LeftLabeledContainer>

      </ModalForm>
    </Modal>
  );
};

export default LocationModal;

import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import FormInputError from "../components/FormInputError";
import MainHeader from "../components/MainHeader";
import Modal from "../components/Modal";
import ModalForm from "../components/ModalForm";
import Spinner from "../components/Spinner";
import { Main } from ".";

const ProfileSettings: React.FC<{}> = () => {
  const { register, handleSubmit, errors } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [awsUser, setAwsUser] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [requestError, setRequestError] = useState("");

  const name = awsUser?.attributes?.name ?? "Unknown";
  const email = awsUser?.attributes?.email ?? "Unknown";

  useEffect(() => {
    if (awsUser) {
      return;
    }
    Auth.currentAuthenticatedUser().then((data) => {
      setAwsUser(data);
    });
  });

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    Auth.updateUserAttributes(awsUser, {
      email: formData.email.trim(),
      name: formData.name
        .split(" ")
        .map((name: string) => name.trim())
        .filter((name: string) => !!name)
        .join(" "),
    })
      .then(() => {
        Auth.currentAuthenticatedUser().then((data) => {
          setAwsUser(data);
        });
        setIsEditing(false);
      })
      .catch((err) => setRequestError(err.message))
      .finally(() => setIsSubmitting(false));
  };
  const handleSubmitResult = handleSubmit(onFormSubmit);
  const componentState = {
    handleSubmit: handleSubmitResult,
    register,
    errors,
    name,
    email,
    isEditing,
    setIsEditing,
    isChangingPassword,
    setIsChangingPassword,
    isSubmitting,
    awsUser,
    passwordChanged,
    setPasswordChanged,
    requestError,
    setRequestError,
  };

  return (
    <Main>
      <ChangePasswordModal {...componentState} />
      <form onSubmit={handleSubmitResult}>
        <div className="flex justify-between pb-8 pt-5 mb-8 border-b">
          <MainHeader>Profile</MainHeader>
          <ControlButtons {...componentState} />
        </div>
        <div className="space-y-16">
          <Details {...componentState} />
        </div>
      </form>
    </Main>
  );
};

const ControlButtons: React.FC<any> = ({ isEditing, setIsEditing, isSubmitting, setRequestError }) => {
  if (!isEditing) {
    return (
      <Button
        onClick={() => {
          setRequestError("");
          setIsEditing(true);
        }}
        icon="ion-edit"
        iconSize={18}
      >
        Edit
      </Button>
    );
  }
  return (
    <div>
      <Button onClick={() => setIsEditing(false)} disabled={isSubmitting}>
        Cancel
      </Button>
      <button
        type="submit"
        className={`copper-button-confirm ${isSubmitting && "text-transparent"} ml-2 bg-color-red`}
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <div className="absolute flex align-center justify-center mx-auto -my-0.5 px-1 md:my-0 md:px-1.5">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        Save
      </button>
    </div>
  );
};

const Details: React.FC<any> = (props) => {
  const { register, errors, name, email, isEditing, setIsChangingPassword, passwordChanged, requestError } = props;
  if (!isEditing) {
    return (
      <>
        <DetailRow name={"Email"} value={email} />
        <DetailRow name={"Name"} value={name} />
        <DetailRow name={"Password"} value="••••••••" />
      </>
    );
  }

  const formErrorDisplay = (err: any) =>
    err
    && err.type === "required" && (
      <div className="h-0 overflow-visible label-error-sm pl-1.5 label-error">{err.message}</div>
    );

  return (
    <>
      <div className="flex flex-row justify-start h-10">
        <div className="text-responsive-base my-auto w-56">Email</div>
        <div className="flex flex-col">
          <input
            className="copper-input w-64 ml-1px mt-1px"
            ref={register({ required: "Email required" })}
            id="email"
            name="email"
            defaultValue={email}
          />
          {formErrorDisplay(errors?.email)}
        </div>
      </div>
      <div className="flex flex-row justify-start h-10">
        <div className="text-responsive-base my-auto w-56">Name</div>
        <div className="flex flex-col">
          <input
            className="copper-input w-64  ml-1px mt-1px"
            ref={register({ required: "Name required" })}
            id="name"
            name="name"
            defaultValue={name}
          />
          {formErrorDisplay(errors?.name)}
        </div>
      </div>
      <div className="flex flex-row justify-start h-10">
        <div className="text-responsive-base my-auto w-56">Password</div>
        {passwordChanged
          ? <span className="label-success my-auto">Password changed!</span>
          : <Button onClick={() => setIsChangingPassword(true)}>Change password</Button>}
      </div>
      <div className="label-error">{requestError}</div>
    </>
  );
};

const DetailRow: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <div className="text-responsive-base flex flex-row justify-start h-10">
      <div className="my-auto w-56">{name}</div>
      <div className="my-auto pl-3.5 py-2">{value}</div>
    </div>
  );
};

const ChangePasswordModal: React.FC<any> = ({
  isChangingPassword,
  setIsChangingPassword,
  setPasswordChanged,
  awsUser,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const onCancel = () => {
    setIsChangingPassword(false);
    setNewPassword("");
  };

  const onSubmit = (formData: { oldPassword: string; newPassword: string }) => {
    setIsSubmitting(true);
    const { oldPassword, newPassword } = formData;
    Auth.changePassword(awsUser, oldPassword, newPassword)
      .then(() => {
        setPasswordChanged(true);
        onCancel();
      })
      .catch((err) => setRequestError(err.message))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal visible={isChangingPassword} onClickOutside={onCancel} maxWidthClass="max-w-xl">
      <ModalForm
        title={"Change password"}
        handleSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        submitError={requestError}
        confirmButtonText="Change password"
        buttonClass="copper-button-confirm whitespace-nowrap"
      >
        <div className="flex justify-between">
          <div className="my-auto">Old Password</div>
          <div className="flex flex-col w-full max-w-sm">
            <input
              ref={register({ required: "Old password required" })}
              type="password"
              id="oldPassword"
              name="oldPassword"
              className="copper-input max-w-sm"
            />
            <FormInputError error={errors?.oldPassword?.message} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="my-auto">New Password</div>
          <div className="flex flex-col w-full max-w-sm">
            <input
              ref={register({ required: "New password required" })}
              type="password"
              id="newPassword"
              name="newPassword"
              className="copper-input max-w-sm"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            />
            <FormInputError error={errors?.newPassword?.message} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="my-auto">Confirm Password</div>
          <div className="flex flex-col w-full max-w-sm">
            <input
              ref={register({
                required: "Confirm password required",
                validate: (value) => value === newPassword || "Does not match new password",
              })}
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className="copper-input"
            />
            <FormInputError error={errors?.confirmPassword?.message} />
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
};

export default ProfileSettings;

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InBetweenSettings: React.FC<any> = ({ filterSettings, setFilterSettings }) => {
  return (
    <>
      <DatePicker
        selected={filterSettings.dateRange.startDate}
        onChange={(date: any) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              startDate: date,
            },
          })}
        customInput={<input className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20" />}
      />
      <div className="my-auto pl-1 pr-1">and</div>
      <DatePicker
        selected={filterSettings.dateRange.endDate}
        onChange={(date: any) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              endDate: date,
            },
          })}
        customInput={<input className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20" />}
      />
    </>
  );
};

export default InBetweenSettings;

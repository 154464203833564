import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const { REACT_APP_API_ENDPOINT } = process.env;

const BuildCloverOptions = (props: any) => {
  const { location, updateLocation, merchantId, isSubmitting, setIsSubmitting, setRequestError } = props;
  const isConnectedToClover = !(location.settings?.clover_payments?.merchant_id === null);
  const popupUrl = `${REACT_APP_API_ENDPOINT}/clover/authorize?location=${location.id}`;
  const openCloverAuthPopup = () => {
    const popup = window.open(popupUrl, window.name);
    window.addEventListener("message", (event) => {
      console.log("event", event);
      updateLocation();
      popup?.close();
    });
  };

  const disconnectFromClover = () => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;
    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          clover_payments: "",
        },
      },
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const connectButton = (
    <button
      className={`copper-button-confirm ${false && "text-transparent"}`}
      onClick={openCloverAuthPopup}
      disabled={false}
    >
      Connect to Clover
    </button>
  );

  const disconnectButton = (
    <Button onClick={disconnectFromClover}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const cloverAccountId = isConnectedToClover ? location.settings?.clover_payments?.merchant_id : "";

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="space-y-4">
        <div className="text-responsive-sm pt-16 text-center w-full">
          {isConnectedToClover ? "You are connected to Clover." : "Login with Clover to connect your account."}
        </div>
        <div className="flex justify-center">
          <span className="text-responsive-sm pt-0.5 pr-2">Merchant ID:</span>
          <div>
            <input
              className="bg-gray-100 text-gray-500 align-top text-xs md:text-sm; text-center p-1 w-72 h-5"
              value={cloverAccountId}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">{isConnectedToClover ? disconnectButton : connectButton}</div>
    </div>
  );
};

export const CloverTab = {
  name: "Clover",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className=""
        width="112"
        alt="clover-logo"
        src={`/img/payment_options/clover-${active ? "active" : "inactive"}.svg`}
      />
    </>
  ),
  headerText: "Connect with Clover",
  build: BuildCloverOptions,
};

export default React.memo(BuildCloverOptions);

import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import logo from "../logo.svg";
import { useAuth } from "../services/auth-context";

interface MenuItemType {
  exact?: boolean;
  to: string;
  name: string;
  isHide?: boolean | undefined | false;
}

const Sidebar: FunctionComponent = () => {
  const { isReseller, isAdmin, role, user } = useAuth();
  const _isReseller = isReseller();
  const MenuItem = (props: MenuItemType) =>
    !props.isHide
      ? (
        <NavLink
          exact={props.exact}
          to={props.to}
          className="text-gray-light text-base hover:bg-sgray-100 hover:text-gray-900 group flex items-center px-4 py-3 pl-6 font-medium"
          activeClassName="bg-white text-gray-active "
        >
          {props.name}
        </NavLink>
      )
      : null;

  const isMerchantAccount = user?.account?.startsWith("mch") ?? false;
  const mcpEnabled = user?.accountData?.mcp_enabled === true;
  const enablePlus = isMerchantAccount && role === "owner" && mcpEnabled;

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1 border-gray-light border-r border-opacity-40	 bg-gray-background">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-6 w-32 m-auto mt-0.5" src={logo} alt="Workflow" />
            </div>
            <nav className="mt-11 flex-1 px-2 bg-gray-50 space-y-1">
              <MenuItem to={"/"} name={"Home"} isHide={role !== "owner"} exact />
              <MenuItem to={"/plus"} name={"Plus"} isHide={!enablePlus} />
              <MenuItem to={"/labels"} name={"Labels"} isHide={!enablePlus} />
              <MenuItem to={"/transactions"} name={"Transactions"} isHide={_isReseller || isAdmin} />
              <MenuItem to={"/merchants"} name={"Merchants"} isHide={!_isReseller || isAdmin} />
              <MenuItem to={"/resellers"} name={"Resellers"} isHide={!isAdmin} />
              <MenuItem to={"/devices"} name={"Devices"} isHide={!(isMerchantAccount && role === 'owner')} />
              <MenuItem to={"/locations"} name={"Locations"} isHide={_isReseller || isAdmin || role === "employee"} />
              <MenuItem to={"/settings"} name={"Settings"} isHide={role !== "owner"} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);

import React from "react";
import Ionicon from "react-ionicons";

const Button: React.FC<{
  children: string | Array<JSX.Element> | JSX.Element;
  onClick: any;
  disabled?: boolean;
  icon?: any;
  iconSize?: number;
  className?: string;
}> = ({ children, onClick, disabled, icon, iconSize, className = "" }) => {
  return (
    <button
      type="button"
      className={`copper-button-cancel${disabled ? "-disabled" : ""} h-10 bg-white ${className}`}
      onClick={onClick ? onClick : null}
      disabled={!!disabled}
    >
      <div className="flex -mt-1px -mx-0.5 px-1">
        {!!icon && (
          <div className="mt-1px mr-1 -ml-1">
            <Ionicon icon={icon} fontSize={`${iconSize ? iconSize : 20}px`} color="#748294" />
          </div>
        )}
        {children}
      </div>
    </button>
  );
};

export default Button;

import { API } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import NameAddressForm from "../NameAddressForm";

const ResellerModal: React.FC<{
  account?: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  removeBlankFormValues: boolean;
  sendType?: boolean;
}> = ({ account, onCancel, onSubmitSuccess, onClickOutside, visible, removeBlankFormValues, sendType }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    const body = !removeBlankFormValues ? formData : removeEmptyObjectProps(formData);
    const isEdit = !!account;
    const base = "/accounts";
    const endpoint = `${base}${isEdit ? `/${account.id}` : ""}`;
    const accountType = sendType ? { type: "reseller" } : {};
    API.post("Conduit", endpoint, {
      body: { ...body, ...accountType },
    })
      .then((merchant) => {
        onSubmitSuccess(merchant);
      })
      .catch((err) => {
        setRequestError(err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={`${!!account ? "Edit" : "New"} reseller`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
      >
        <NameAddressForm
          register={register}
          errors={errors}
          name={account?.name}
          email={account?.email}
          address={account?.address}
          usesDisplayName={false}
          showEmail={true}
          preselectedCountry={account?.address?.country}
        />
      </ModalForm>
    </Modal>
  );
};

export default ResellerModal;

import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const BuildWorldPayOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
}) => {
  const isConnected = !(location.settings?.express_payments?.account_id === null
    || location.settings?.express_payments?.acceptor_id === null);

  const SetLocation = (data: any) => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;
    let postBody = {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          express_payments: data,
        },
      },
    } as any;
    return API.post("Conduit", endpoint, postBody);
  };

  const disconnectFromWorldPay = () => {
    SetLocation("")
      .then(() => {
        updateLocation();
        reset({ account_id: "", acceptor_id: "" });
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveSettings = (formData: any) => {
    const { account_id, acceptor_id, account_token, terminal_id } = formData;
    SetLocation({ account_id, acceptor_id, account_token, terminal_id })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromWorldPay}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Disconnect</span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>Connect</span>
      </>
    </button>
  );

  return (
    <form className="h-full" onSubmit={handleSubmit(saveSettings)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-3">
          <div className="text-base text-center w-full">
            Learn how to collect your information <br />from{" "}
            <a className="text-copper-purple" href="https://worldpay.com">Worldpay.</a>
          </div>
          <div>
            <label htmlFor="account-id" className="block text-base py-1 text-gray-700">Account ID</label>
            <input
              ref={register({ required: "Account id required" })}
              id="account-id"
              name="account_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.express_payments?.account_id}
            />
          </div>
          <div>
            <label htmlFor="terminal-id" className="block text-base py-1 text-gray-700">Terminal ID</label>
            <input
              ref={register({ required: "Terminal id required" })}
              id="terminal-id"
              name="terminal_id"
              autoComplete="off"
              disabled={isConnected}
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.express_payments?.terminal_id}
            />
          </div>
          <div>
            <label htmlFor="account-token" className="block text-base py-1 text-gray-700">
              Account Token
            </label>
            <input
              ref={register({ required: "Account token required" })}
              id="account-token"
              name="account_token"
              disabled={isConnected}
              autoComplete="off"
              type={isConnected ? "password" : "text"}
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={isConnected ? "**************" : location.settings?.express_payments?.account_token}
            />
          </div>
          <div>
            <label htmlFor="acceptor-id" className="block text-base py-1 text-gray-700">
              Acceptor ID
            </label>
            <input
              ref={register({ required: "Aceptor id required" })}
              id="acceptor-id"
              name="acceptor_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.express_payments?.acceptor_id}
            />
          </div>
        </div>
        <div className="flex justify-between">
          {!!requestError ? <span className="label-error my-auto">{requestError}</span> : <div />}
          {isConnected ? disconnectButton : connectButton}
        </div>
      </div>
    </form>
  );
};

export const WorldPayTab = {
  name: "WorldPay",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className=""
        width="112"
        alt="world-pay-logo"
        src={`/img/payment_options/worldpay-${active ? "active" : "inactive"}.svg`}
      />
    </>
  ),
  headerText: "Connect with WorldPay",
  build: BuildWorldPayOptions,
};

export default React.memo(BuildWorldPayOptions);

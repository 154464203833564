const WifiStrengthDescription = (device: any): string => {
    if (!device) {
        return ""
    }

    let rssi = device.wifi_rssi
    const inRange = (min: number, max: number): boolean => {
        return rssi >= min && rssi <= max
    }
    switch (true) {
        case device.status == "offline":
            return "Offline"

        case rssi == null || rssi == 0:
            return "Online"

        case inRange(-100, -80):
            return "Poor"

        case inRange(-79, -70):
            return "Okay"

        case inRange(-69, -60):
            return "Good"

        case inRange(-59, 0):
            return "Excellent"

        default:
            return "Invalid"
    }
}

export default WifiStrengthDescription;
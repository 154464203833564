import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../services/auth-context";
import Modal from "../../Modal";
import { OtherSettingTab } from "./OtherSettingsTab";
import { BluefinTab } from "./PaymentProviders/BluefinSettings";
import { CloverTab } from "./PaymentProviders/CloverSettings";
import { PoyntTab } from "./PaymentProviders/PoyntSettings";
import { TsysTab } from "./PaymentProviders/TsysSettings";
import { StripeTab } from "./PaymentProviders/StripeSettings";
import { WorldPayTab } from "./PaymentProviders/WorldPaySettings";

const PaymentSettingsModal: React.FC<{
  location?: any;
  onCancel: any;
  onClickOutside?: any;
  visible: boolean;
  merchantId: any;
  updateLocation: Function;
}> = ({ location, visible, onClickOutside, onCancel, merchantId, updateLocation }) => {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [selectedPaymentSetting, setSelectedPaymentSetting] = useState(paymentSetting.otherSettings);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const { isReseller } = useAuth();

  const nav = buildNav(
    Object.values(paymentSetting),
    selectedPaymentSetting,
    setSelectedPaymentSetting,
    setRequestError,
    isReseller(),
  );

  return (
    <Modal onClickOutside={onClickOutside} visible={visible} maxWidthClass="max-w-screen-sm">
      <div className="flex" style={{ height: "36rem" }}>
        <div className="flex flex-col -ml-4 -my-4 w-52 bg-gray-100">
          <div className="flex flex-col h-full justify-start	">{nav}</div>
        </div>

        <div className="flex flex-col w-full h-full bg-white">
          {buildHeader(selectedPaymentSetting, onCancel)}
          <div className="flex flex-col h-full pl-responsive-sm">
            {selectedPaymentSetting.build({
              location,
              register,
              handleSubmit,
              reset,
              setValue,
              merchantId,
              updateLocation,
              isSubmitting,
              setIsSubmitting,
              requestError,
              setRequestError,
              isReseller: isReseller(),
              errors,
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const buildHeader = (paymentSetting: any, onCancel: any) => {
  return (
    <>
      <div
        className="cursor-pointer flex justify-end mx-auto text-gray-500 leading-none text-4xl -mt-3 mr-3 right-0 absolute w-6 h-6"
        onClick={onCancel}
      >
        &times;
      </div>
      <div className="m-2 pb-2 text-center font-medium text-responsive-lg">{paymentSetting.headerText}</div>
    </>
  );
};

const buildNav = (
  paymentSettings: any,
  selectedPaymentSetting: any,
  setSelectedPaymentSetting: any,
  setRequestError: any,
  isReseller: boolean,
) => {
  return paymentSettings.map((paymentSetting: any) => {
    const isSelected = selectedPaymentSetting.name === paymentSetting.name;
    return (paymentSetting?.resellersOnly === isReseller || paymentSetting?.resellersOnly === false) && (
      <div
        key={paymentSetting.name}
        className={`flex justify-center w-52 h-full max-h-28 ${
          isSelected ? " bg-white cursor-default" : " cursor-pointer"
        }`}
        onClick={() => {
          setRequestError("");
          setSelectedPaymentSetting(paymentSetting);
        }}
      >
        {isSelected && <div className="bg-copper-purple -mr-1 w-1"></div>}
        <div className="m-auto">{paymentSetting.navContent(isSelected)}</div>
      </div>
    );
  });
};

const paymentSetting = {
  poynt: PoyntTab,
  clover: CloverTab,
  bluefin: BluefinTab,
  tsys: TsysTab,
  worldPay: WorldPayTab,
  stripe: StripeTab,
  otherSettings: OtherSettingTab,
} as const;

export default PaymentSettingsModal;

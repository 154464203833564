import { API } from "aws-amplify";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { LogicBadge } from "../components/Badge";
import Button from "../components/Button";
import getIcon from "../components/paymentField/img";
import ResourceHeader from "../components/ResourceHeader";
import Spinner from "../components/Spinner";
import TitledDetailRows from "../components/TitledDetailRows";
import { useAuth } from "../services/auth-context";
import { getTransactionStatus } from "../util/copperLogic";
import Main from "./containers/Main";

type Props = {
  id?: string;
};

const Ticket: React.FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const { params } = props.match;
  // const [transaction, setTransaction] = useState<any[] | any>(null);
  const [ticket, setTicket] = useState<any[] | any>(null);
  const [location, setLocation] = useState<any[] | any>(null);
  const { user, isOverrideUser } = useAuth();

  const printTicket = () => {
    API.post("Conduit", `/tickets/${params.id}/print`, {});
  };

  useEffect(() => {
    const get = (path: string) => API.get("Conduit", path, {});

    get(`/tickets/${params.id}?include[]=transactions`).then(async ticket => {
      await Promise.all([
        get(`/locations/${ticket.location}`).then(setLocation),
      ]);

      setTicket(ticket);
    });
  }, [params]);

  if (!ticket) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const succeesfulTransactions = ticket.transactions.filter((txn: any) => txn.status === "succeeded");
  const netTransactions = succeesfulTransactions.filter((txn: any) => !txn.refunded);

  const sale = netTransactions.reduce((sum: number, txn: any) =>
    sum + (
      txn.amount - txn.tip_amount
      - (txn.adjustment_amount || 0)
      - (txn.donation_amount || 0)
    ), 0);

  const tip = netTransactions.reduce((sum: number, txn: any) => sum + txn.tip_amount, 0);

  const donation = netTransactions.reduce((sum: number, txn: any) => sum + (txn.donation_amount || 0), 0);

  const refund = succeesfulTransactions.reduce((sum: number, txn: any) => sum + (txn.refunded_amount || 0), 0);

  const total = sale + tip + donation;

  const TH: any = (props: any) => <th className="text-left font-normal text-gray-400 text-lg">{props.children}</th>;

  return (
    <Main>
      <div className="pb-7 mb-8 border-b">
        <div className="flex flex-row justify-between pb-6">
          <ResourceHeader>Ticket</ResourceHeader>
        </div>
        <div className="flex flex-row text-responsive-3xl my-auto">
          <span className="font-medium mr-3">{ticket.reference}</span>
          <div className="flex my-auto">
            <LogicBadge
              value={sale >= ticket.total ? "paid" : "unpaid"}
              definition={{
                paid: "green",
                unpaid: "red",
              }}
            />
          </div>
          <span className="ml-auto font-medium mr-3">${(ticket.total / 100).toFixed(2)}</span>
          {" "}
        </div>
      </div>

      <TitledDetailRows
        details={[
          {
            name: "Date",
            value: DateTime
              .fromISO(`${ticket.date}T${ticket.time}`, { setZone: true })
              .toLocaleString(DateTime.DATETIME_MED),
          },
          {
            name: "Location",
            value: location.display_name,
          },
          {
            name: "Label",
            value: ticket.name,
          },
          {
            name: "Server",
            value: ticket.server,
          },
        ]}
      />

      <div className="text-xl font-medium border-b pb-6 mb-6 pt-6">Transactions</div>
      <table className="table-fixed divide-y divide-gray-200 min-w-full">
        <thead>
          <tr>
            <TH>Payment method</TH>
            <TH>Approval code</TH>
            <TH>Amount</TH>
            <TH>Sale</TH>
            <TH>Tip</TH>
            {donation > 0 && <TH>Donation</TH>}
            {refund > 0 && <TH>Refund</TH>}
            <TH>Status</TH>
            <TH>Transaction</TH>
          </tr>
        </thead>
        <tbody>
          {ticket.transactions.map((transaction: any) => (
            <tr key={transaction.id}>
              <td className="py-5">
                <img
                  className="inline-block h-8 w-8 -mt-1 mr-2"
                  alt="icon-payment"
                  src={getIcon(
                    transaction.payment_method?.type === "vip" ? "vip" : transaction.payment_method?.card?.brand,
                  )}
                />
                <span>
                  {`•••• ${
                    transaction.payment_method?.card?.last4
                    || transaction.payment_method?.bank_account?.account_number.replace(/\*/g, "")
                  }`}
                </span>
              </td>
              <td>{transaction.approval_code || "N/A"}</td>
              <td>${renderAmount(transaction.amount)}</td>
              <td>
                ${renderAmount(
                  transaction.amount - transaction.tip_amount - (transaction.donation_amount || 0)
                    - (transaction.adjustment_amount || 0),
                )}
              </td>
              <td>${renderAmount(transaction.tip_amount)}</td>
              {donation > 0 && <td>${renderAmount(transaction.donation_amount)}</td>}
              {refund > 0 && <td>(${renderAmount(transaction.refunded_amount)})</td>}
              <td>
                <LogicBadge
                  value={getTransactionStatus(transaction)}
                  definition={{
                    succeeded: "green",
                    failed: "red",
                    refunded: "yellow",
                    "partial refund": "yellow",
                    declined: "red",
                  }}
                />
              </td>
              <td>
                <Link className="underline" to={`/transactions/${transaction.id}`}>
                  View details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <TitledDetailRows
        className="pt-16"
        title={
          <div className="flex">
            Payment confirmation
            <Button
              className="ml-auto"
              onClick={printTicket}
              icon="ion-printer"
            >
              Print
            </Button>
          </div>
        }
        details={[
          { name: "Sale", value: `$${renderAmount(sale)}` },
          { name: "Tip", value: `$${renderAmount(tip)}` },
          { name: "Donation", value: `$${renderAmount(donation)}`, isHide: donation === 0 },
          { name: "Total", value: `$${renderAmount(total)}` },
        ]}
      />
    </Main>
  );
};

export default Ticket;

function renderAmount(amount: number) {
  return (amount / 100).toFixed(2);
}
